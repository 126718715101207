/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  useParams,
  useRouteMatch,
  Route,
  Redirect,
} from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { getPortfolioById } from '../../data/store/portfolio/portfolioActions';

// import { SuggestedPortfolio } from './SuggestedPortfolio/SuggestedPortfolio';
import { Optimization } from './Optimization/Optimisation';

import { StepTransactions } from './StepTransactions/StepTransactions';
import { StepSettings } from './StepSettings/StepSettings';
import { Button, Col, Layout, Row } from 'antd';

import './styles.scss';
import { getCurrency, getInstrumentsAll } from '../../data/store/instruments/instrumentsActions';

const { Header } = Layout;

export const StepRouter = ({ userId, optAlert, setOptAlert, setOptStart }) => {
  const { t } = useTranslation('');
  const { portfolioId } = useParams();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();

  const [pdfSource, setPDFSource] = useState(null);
  const [settings, setSettings] = useState(null);
  const [optimalPortfolio, setOptimalPortfolio] = useState(null);

  useEffect(() => {
    dispatch(getCurrency());
    dispatch(getInstrumentsAll({ limit: -1, offset: 0, type: 'cashes-only' }));
    if (portfolioId) {
      dispatch(getPortfolioById({ portfolioId }));
    }
  }, [portfolioId]);

  return (
    <div className='portfolio-wrapper'>
      <Header className='headerStyle'>
        <Row className="overview-tabs-wrapper">
            <Col className='tabs-tab-wrapper' span={18}>
              <Button
                type="link"
                className='overview-btn ant-btn-active'
              >
                {t('PORTFOLIO_OPTIMISATION')}
              </Button>
            </Col>
        </Row>
      </Header>
      <Route exact path={`${url}/`}>
        <Redirect to={`${url}/opt-settings`} />
      </Route>
      <Route exact path={`${url}/opt-settings`}>
        <StepSettings
          id={userId}
          portfolioId={portfolioId}
          settings={settings}
          setSettings={setSettings}
          optAlert={optAlert}
          setOptAlert={setOptAlert}
          setOptStart={setOptStart}
        />
      </Route>
      <Route exact path={`${url}/portfolioOptimization`}>
        <Optimization
          userId={userId}
          portfolioId={portfolioId}
          pdfSource={pdfSource}
          setPDFSource={setPDFSource}
          settings={settings}
          setOptimalPortfolio={setOptimalPortfolio}
          optimalPortfolio={optimalPortfolio}
          optAlert={optAlert}
          setOptAlert={setOptAlert}
          setOptStart={setOptStart}
        />
      </Route>
      <Route exact path={`${url}/transactions`}>
        <StepTransactions
          id={userId}
          portfolioId={portfolioId}
          pdfSource={pdfSource}
          setPDFSource={setPDFSource}
          optAlert={optAlert}
          setOptAlert={setOptAlert}
          setOptStart={setOptStart}
        />
      </Route>
      <Route path='*'>
        <Redirect to={`${url}/opt-settings`} />
      </Route>

    </div>
  );
};

StepRouter.propTypes = {
  id: PropTypes.string,
  current: PropTypes.number,
  setCurrent: PropTypes.func,
  optimalPortfolio: PropTypes.object,
  selectOptimalPortfolio: PropTypes.func,
  url: PropTypes.string,
  isAdmin: PropTypes.bool,
  isAdviser: PropTypes.bool,
  optAlert: PropTypes.object,
  setOptAlert: PropTypes.func,
  setOptStart: PropTypes.func,
};
