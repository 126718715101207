export const OnboardingSW = {
  WELCOME: 'Välkommen till Citroneer!',
  WHAT_TYPE_OF_ACCOUNT: 'Viken typ av konto vill du öppna?',
  WANT_TO_START_A_PERSONAL:
    'Vill du starta ett privat konto eller ett företagskonto?',
  PRIVATE_CUSTOMER: 'Privatkund',
  BUSINESS_CUSTOMER: 'Företagskund',
  PRIVAT_ORGANISATION_NUMBER: 'Person-/organisationsnummer',
  PRIVAT_NUMBER: 'Personnummer',
  ORGANISATION_NUMBER: 'Organisationsnummer',
  ENTER_ORGANIZATION_NUMBER: 'Ange person-/organisationsnummer',
  MOVE_ON: 'Gå vidare',
  RFA19: 'Vill du identifiera dig eller skriva under med BankID på den här datorn eller med ett Mobilt BankID?',
  MOBILEID_HEADER: 'Starta BankID-appen på din telefon eller surfplatta. Tryck på QR-kodknappen i appen. Rikta kameran mot QR-koden nedan.',
  USE_BANKID: 'Använd BankID på den här enheten',
  THE_LOGIN_FAILED: 'Inloggningen misslyckades',
  LOGIN_FAILED_TEXT: 'Det gick inte att skanna QR-koden. Starta BankID-appen och skanna QR-koden. Kontrollera att BankID-appen är uppdaterad. Om du inte har BankID-appen behöver du installera den och beställa ett BankID från din internetbank. Installera appen från din appbutik eller https://install.bankid.com.',
  TRY_AGAIN: 'Försök igen',
  LOGIN_WITH_BANKID: 'Logga in med Testa BankID',
  TYPE_YOUR_SECURITY: 'Ange din säkerhetskod i BankID-appen och välj Identifiera.',
};
