import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const Container = ({ children, className = '', id, widthP, mb = '14px', padding = '10px' }) => {
  const atrWidth = `${widthP}%` || '';
  className = className ? 'detail-container' + ' ' + className : 'detail-container';
  return <div id={id} className={className} style={{ width: atrWidth, marginBottom: mb, padding }}>{children}</div>;
};

Container.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  id: PropTypes.string,
  widthP: PropTypes.number,
  mb: PropTypes.string,
  padding: PropTypes.string,
};

export default Container;
