/* eslint-disable camelcase */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Modal,
  Row,
  Select,
} from 'antd';
import { ReactComponent as Decrease } from '../../assets/decrease-icon.svg';
import { ReactComponent as Increase } from '../../assets/increase-icon.svg';

import './style.scss';
import CustomSelect from '../../components/CustomSelect/CustomSelect';

const ModalFilterCandidat = ({
  type = 'suggestions',
  source,
  setFilter,
  openFilter,
  handleCloseFilter,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation('');

  const filterInit = {
    current_mean_return: { min: 0, max: 0 },
    current_volatility: { min: 0, max: 0 },
    current_cvar: { min: 0, max: 0 },
  };

  const [portfolioSelectList, setPortfolioSelectList] = useState(['All']);
  const [spec, setSpec] = useState(filterInit);

  useEffect(() => {
    console.log('spec', spec);
  }, [spec]);

  const textSort = (a, b, direction = 1) => {
    if (a?.toLowerCase() < b?.toLowerCase()) {
      return -1 * direction;
    }
    if (a?.toLowerCase() > b?.toLowerCase()) {
      return 1 * direction;
    }
    return 0;
  };

  const initValue = {
    portfolioList: ['All'],
  };

  useEffect(() => {
    form.setFieldsValue(initValue);
  }, []);

  useEffect(() => {
    let portfolioList = ['All'];
    source?.forEach(
      (i) => !portfolioList.includes(i?.name) && portfolioList.push(i?.name)
    );
    portfolioList = portfolioList?.sort((a, b) => textSort(a, b));
    setPortfolioSelectList(portfolioList);
  }, [source]);

  const handleDecrease = (target, arg) => {
    const newDig = Math.round((+spec[target][arg] - 0.1) * 100) / 100;
    const newFilter = { ...spec };
    newFilter[target][arg] = newDig;
    setSpec(newFilter);
  };

  const handleIncrease = (target, arg) => {
    const newDig = Math.round((+spec[target][arg] + 0.1) * 100) / 100;
    const newFilter = { ...spec };
    newFilter[target][arg] = newDig;
    setSpec(newFilter);
  };

  const handleInputChange = (event, target, arg) => {
    const { value } = event.target;
    const newFilter = { ...spec };
    newFilter[target][arg] = +value;
    setSpec(newFilter);
  };

  const handleRest = () => {
    form.resetFields();
    setSpec(filterInit);
    form.setFieldsValue(initValue);
    setFilter(null);
  };

  const handleCancel = () => {
    handleCloseFilter();
  };

  const handleChangeSelect = () => {
    const values = form.getFieldsValue();
    if (values?.portfolioList?.length > 1) {
      values.portfolioList = values.portfolioList?.filter((i) => i !== 'All');
    }
    if (values?.portfolioList?.length === 0) values.portfolioList = ['All'];
    form.setFieldsValue(values);
  };

  const onSubmit = () => {
    const value = form.getFieldsValue();
    const configFilter = { ...value, ...spec };
    setFilter(configFilter);
    handleCloseFilter();
  };

  return (
    <Modal
      width={448}
      className="filter-modal"
      centered
      open={openFilter}
      onCancel={handleCancel}
      footer={false}
      zIndex={1500}
    >
      <Form
        form={form}
        name="filter-config"
        layout="vertical"
        onFinish={onSubmit}
      >
        <div>
          <h1 className="filter-date-title">{t('CREATION_DATE')}</h1>
          <Row
            style={{ width: '100%', margin: '0 0 20px' }}
            justify={'space-between'}
          >
            <Col className="">
              <Form.Item
                className="custom-required finplan-datepicker-wrap"
                name="create_start_date"
                required={false}
              >
                <DatePicker
                  placeholder={t('ENTER_START')}
                  className="finplan-datepicker"
                />
              </Form.Item>
            </Col>
            <Col className="">
              <Form.Item
                className="custom-required finplan-datepicker-wrap"
                name="create_end_date"
                required={false}
              >
                <DatePicker
                  placeholder={t('ENTER_END')}
                  className="finplan-datepicker"
                />
              </Form.Item>
            </Col>
          </Row>
          {type === 'approved' && (
            <>
              <h1 className="filter-date-title">{t('APPROVAL_DATE')}</h1>
              <Row
                style={{ width: '100%', margin: '0 0 20px' }}
                justify={'space-between'}
              >
                <Col className="">
                  <Form.Item
                    className="custom-required finplan-datepicker-wrap"
                    name="approved_start_date"
                    required={false}
                  >
                    <DatePicker
                      placeholder={t('ENTER_START')}
                      className="finplan-datepicker"
                    />
                  </Form.Item>
                </Col>
                <Col className="">
                  <Form.Item
                    className="custom-required finplan-datepicker-wrap"
                    name="approved_end_date"
                    required={false}
                  >
                    <DatePicker
                      placeholder={t('ENTER_END')}
                      className="finplan-datepicker"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          <Divider className="filter-divider" />
          <Row className="profile-data-row">
            <Col>
              <Form.Item
                label={t('PORTFOLIO_NAME')}
                name="portfolioList"
                required={false}
              >
                <CustomSelect
                  mode={'multiple'}
                  maxTagCount="responsive"
                  maxTagTextLength={12}
                  onChange={handleChangeSelect}
                  className="custom-select"
                >
                  {portfolioSelectList?.map((i) => (
                    <Select.Option key={uuidv4()} value={i}>
                      {i}
                    </Select.Option>
                  ))}
                </CustomSelect>
              </Form.Item>
            </Col>
          </Row>
          <Divider className="filter-divider" />
          <Row justify={'space-between'} style={{ marginBottom: '20px' }}>
            <Col className="filter-control-title">{t('RETURN')}</Col>
            <Col className="filter-control-wrapper">
              <div className="controll-wrapper">
                <div
                  className="controll-decrease"
                  onClick={() => handleDecrease('current_mean_return', 'min')}
                >
                  <Decrease />
                </div>
                <input
                  type={'number'}
                  className="control-min-value"
                  value={spec?.current_mean_return.min}
                  onChange={(event) =>
                    handleInputChange(event, 'current_mean_return', 'min')
                  }
                />
                <div
                  className="controll-increase"
                  onClick={() => handleIncrease('current_mean_return', 'min')}
                >
                  <Increase />
                </div>
              </div>
              <span className="controll-delimiter">-</span>
              <div className="controll-wrapper">
                <div
                  className="controll-increase"
                  onClick={() => handleDecrease('current_mean_return', 'max')}
                >
                  <Decrease />
                </div>
                <input
                  type={'number'}
                  className="control-max-value"
                  value={spec?.current_mean_return.max}
                  onChange={(event) =>
                    handleInputChange(event, 'current_mean_return', 'max')
                  }
                />
                <div
                  className="controll-increase"
                  onClick={() => handleIncrease('current_mean_return', 'max')}
                >
                  <Increase />
                </div>
              </div>
            </Col>
          </Row>
          <Divider className="filter-divider" />
          <Row justify={'space-between'} style={{ marginBottom: '20px' }}>
            <Col className="filter-control-title">{t('VOLATILITY')}</Col>
            <Col className="filter-control-wrapper">
              <div className="controll-wrapper">
                <div
                  className="controll-decrease"
                  onClick={() => handleDecrease('current_volatility', 'min')}
                >
                  <Decrease />
                </div>
                <input
                  type={'number'}
                  className="control-min-value"
                  value={spec?.current_volatility.min}
                  onChange={(event) =>
                    handleInputChange(event, 'current_volatility', 'min')
                  }
                />
                <div
                  className="controll-increase"
                  onClick={() => handleIncrease('current_volatility', 'min')}
                >
                  <Increase />
                </div>
              </div>
              <span className="controll-delimiter">-</span>
              <div className="controll-wrapper">
                <div
                  className="controll-increase"
                  onClick={() => handleDecrease('current_volatility', 'max')}
                >
                  <Decrease />
                </div>
                <input
                  type={'number'}
                  className="control-max-value"
                  value={spec?.current_volatility.max}
                  onChange={(event) =>
                    handleInputChange(event, 'current_volatility', 'max')
                  }
                />
                <div
                  className="controll-increase"
                  onClick={() => handleIncrease('current_volatility', 'max')}
                >
                  <Increase />
                </div>
              </div>
            </Col>
          </Row>
          <Divider className="filter-divider" />
          <Row justify={'space-between'} style={{ marginBottom: '20px' }}>
            <Col className="filter-control-title">{t('CVaR')}</Col>
            <Col className="filter-control-wrapper">
              <div className="controll-wrapper">
                <div
                  className="controll-decrease"
                  onClick={() => handleDecrease('current_cvar', 'min')}
                >
                  <Decrease />
                </div>
                <input
                  type={'number'}
                  className="control-min-value"
                  value={spec?.current_cvar.min}
                  onChange={(event) =>
                    handleInputChange(event, 'current_cvar', 'min')
                  }
                />
                <div
                  className="controll-increase"
                  onClick={() => handleIncrease('current_cvar', 'min')}
                >
                  <Increase />
                </div>
              </div>
              <span className="controll-delimiter">-</span>
              <div className="controll-wrapper">
                <div
                  className="controll-increase"
                  onClick={() => handleDecrease('current_cvar', 'max')}
                >
                  <Decrease />
                </div>
                <input
                  type={'number'}
                  className="control-max-value"
                  value={spec?.current_cvar.max}
                  onChange={(event) =>
                    handleInputChange(event, 'current_cvar', 'max')
                  }
                />
                <div
                  className="controll-increase"
                  onClick={() => handleIncrease('current_cvar', 'max')}
                >
                  <Increase />
                </div>
              </div>
            </Col>
          </Row>
          <Divider className="filter-divider" />
          <Row justify="space-between">
            <Col>
              <Button
                className="portfolio-details-btn filter-reset"
                onClick={handleRest}
              >
                {t('RESET')}
              </Button>
            </Col>
            <Col>
              <Button
                className="portfolio-details-btn filter-apply"
                onClick={onSubmit}
              >
                {t('APPLY')}
              </Button>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
};

export default ModalFilterCandidat;

ModalFilterCandidat.propTypes = {
  type: PropTypes.string,
  source: PropTypes.array,
  openFilter: PropTypes.bool,
  handleCloseFilter: PropTypes.func,
  setFilter: PropTypes.func,
};
