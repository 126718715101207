/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Col, Divider, Form, Input, Row, Select } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  getPortfolioById,
  saveFullPortfolio,
} from '../../data/store/portfolio/portfolioActions';
import { decimalSeparator } from '../../helpers/decimalSeparator';

import Container from '../../components/Container';

import CustomInputNumber from '../../components/CustomInputNumber/CustomInputNumber';
import CustomSelect from '../../components/CustomSelect/CustomSelect';

import MainFooter from '../../components/MainFooter';
import ModalUnsaveData from '../../components/ModalUnsaveData/ModalUnsaveData';

import './style.scss';

const PortfolioEditInf = ({
  userId,
  portfolioId,
  needSave,
  setNeedSave,
  saveAlert,
  setSaveAlert,
}) => {
  const { t } = useTranslation('');
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  const [modalCancelVisible, setModalCancelVisible] = useState(false);

  const { isLoading, portfolioById } = useSelector(
    (state) => state.portfolioReducer
  );

  const dataSourcePortfolio = useMemo(() => {
    if (portfolioById && Object.keys(portfolioById).length) {
      return {
        ID: portfolioById.ID,
        name: portfolioById.name,
        portfolio_code: portfolioById.portfolio_code,
        portfolio_type: portfolioById?.portfolio_type
          ? portfolioById?.portfolio_type.toString()
          : '',
        last_sync_time: portfolioById.last_sync_time,
        is_sync_active: portfolioById.is_sync_active,
        taxation: portfolioById.taxation.toString(),
        tax_rate: portfolioById.tax_rate.toString(),
        deposit_fee: portfolioById.deposit_fee.toString(),
        advisory_fee: portfolioById.advisory_fee.toString(),
        createdAt: portfolioById.createdAt.toString(),
        updatedAt: portfolioById.updatedAt.toString(),
      };
    } else {
      return {};
    }
  }, [portfolioById]);

  useEffect(() => {
    form.setFieldsValue(dataSourcePortfolio);
  }, [dataSourcePortfolio]);

  useEffect(() => {
    saveAlert && saveAlert.flag && setModalCancelVisible(true);
  }, [saveAlert]);

  const editClose = () => {};

  const handleChangeTaxation = (value) => {
    if (+value === 2) {
      form.setFieldValue('tax_rate', '1.086');
    } else {
      form.setFieldValue('tax_rate', '');
    }
  };

  const handleChangeType = (value) => {
    form.setFieldValue('portfolio_type', value);
  };

  const handleChange = (_, allValues) => {
    setNeedSave(hasChanges(allValues));
  };

  function hasChanges (allValues) {
    const {
      name,
      portfolio_code,
      is_sync_active,
      taxation,
      tax_rate,
      deposit_fee,
      advisory_fee,
      portfolio_type,
    } = allValues;
    if (
      name === portfolioById.name &&
      portfolio_code === portfolioById.portfolio_code &&
      is_sync_active === portfolioById.is_sync_active &&
      Number(taxation) === portfolioById.taxation &&
      Number(tax_rate) === portfolioById.tax_rate &&
      Number(deposit_fee) === portfolioById.deposit_fee &&
      Number(advisory_fee) === portfolioById.advisory_fee &&
      portfolio_type === portfolioById?.portfolio_type.toString()
    ) {
      return false;
    } else {
      return true;
    }
  }

  const closeModal = () => {
    setModalCancelVisible(false);
  };

  const cancelWithoutChange = () => {
    const path = saveAlert.path;
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    dispatch(getPortfolioById({ portfolioId }));
    closeModal();
    history.push(path);
  };

  const handleReset = () => {
    form.setFieldsValue(dataSourcePortfolio);
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
  };

  const handleSubmit = () => {
    form.submit();
  };

  const onSubmit = (data) => {
    if (Object.keys(data)?.length) {
      const newPortfolio = {
        data: {
          ...data,
          portfolio_type: +data.portfolio_type,
          tax_rate: +data.tax_rate,
          portfolio_code: +data.portfolio_code,
          deposit_fee: +data.deposit_fee,
          advisory_fee: +data.advisory_fee,
          taxation: +data.taxation,
          clientID: +userId,
          content: portfolioById?.portfolioContent,
          cashes: portfolioById?.portfolioCashes || [],
          unlistedContent: portfolioById?.unlistedContent?.map((i) => {
            return { ...i, financialAssetID: Number(i.financialAssetID) };
          }),
          replaceExisted: false,
        },
        history,
        userId,
        portfolioId: dataSourcePortfolio?.ID,
        path: saveAlert.path,
        modalClose: editClose,
      };
      dispatch(saveFullPortfolio(newPortfolio));
    }
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    closeModal();
  };

  return (
    !isLoading && (
      <>
        <div className="top-btn-wrapper">
          <Button
            // disabled={!needSave}
            className="portfolio-details-btn portfolio-export-btn"
            onClick={handleReset}
          >
            {t('CANCEL')}
          </Button>
          <Button
            disabled={!needSave}
            onClick={handleSubmit}
            className="portfolio-details-btn btn-edit-save"
          >
            {t('SAVE')}
          </Button>
        </div>
        <div className="portfolio-title-wrapper">
          <h2 className="portfolio-container-title">{t('MAIN_INFORMATION')}</h2>
        </div>
        <div className="portfolio-creat-wrapper">
          <Container
            widthP={100}
          >
            <Form
              id="create-portfolio"
              form={form}
              onFinish={onSubmit}
              validateTrigger={['onSubmit', 'onBlur']}
              layout="vertical"
              autoComplete="off"
              onValuesChange={handleChange}
              style={{ paddingTop: '28px' }}
            >
            <Row className="portfolio-form-row">
              <Col>
                <Form.Item
                  label={t('NAME')}
                  name="name"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: t('PLEASE_ENTER_A_NAME'),
                    },
                  ]}
                >
                  <Input className="custom-input" />
                </Form.Item>
              </Col>
              <Col>
                <div className="portfolio-number">
                  <Form.Item
                    label={t('PORTFOLIO_NUMBER')}
                    name="portfolio_code"
                    required={false}
                    rules={[
                      {
                        required: true,
                        message: t('PLEASE_ENTER_PORTFOLIO_NUMBER'),
                      },
                    ]}
                  >
                    <CustomInputNumber width={'360px'} className="custom-input" controls={false} />
                  </Form.Item>
                  <p className="portfolio_code-helper">
                    {t('ENTER_PORTFOLIO_ID_FROM_FA')}
                  </p>
                </div>
              </Col>
              <Col style={{ alignSelf: 'center' }}>
                <div className="portfolio-number-sync">
                  <Form.Item name="is_sync_active" valuePropName="checked">
                    <Checkbox className="experience-background-cell center experience-background-checkbox my-details-checkbox">
                      {t('ACTIVATE_SYNCHRONIZATION')}
                    </Checkbox>
                  </Form.Item>
                </div>
                <div className="portfolio-sync">
                  {portfolioById?.is_sync_active &&
                  portfolioById?.last_sync_time
                    ? (
                    <span style={{ marginBottom: '24px' }}>
                      {t('LAST_SYNC')}:{' '}
                      {new Date(
                        portfolioById?.last_sync_time * 1000
                      ).toLocaleString()}
                    </span>
                      )
                    : (
                        ''
                      )}
                </div>
              </Col>
            </Row>
            <Divider />
              <Row className="portfolio-form-row">
                <Col>
                <Form.Item
                  label={t('TAXATION')}
                  name="taxation"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: t('PLEASE_SELECT_METHOD_TAXATION'),
                    },
                  ]}
                >
                  <CustomSelect
                    className="custom-select"
                    onChange={(value) => handleChangeTaxation(value)}
                  >
                    <Select.Option value="1">{t('CAPITAL_GAIN')}</Select.Option>
                    <Select.Option value="2">{t('FLAT_RATE')}</Select.Option>
                  </CustomSelect>
                </Form.Item>
                </Col>
                <Col>
                <Form.Item
                  label={t('TAX_RATE')}
                  name="tax_rate"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: t('PLEASE_ENTER_TAX_RATE'),
                    },
                  ]}
                >
                  <CustomInputNumber
                    width='360px'
                    percent={true}
                    decimalSeparator={decimalSeparator()}
                    precision={3}
                    className='custom-inputnumber'
                    min={0}
                    step={0.001}
                  />
                </Form.Item>

                </Col>
              </Row>
              <Divider />
              <Row className="portfolio-form-row">
                <Col>
                <Form.Item
                    label={t('DEPOSIT_FEE')}
                    name="deposit_fee"
                    required={false}
                    rules={[
                      {
                        required: true,
                        message: t('PLEASE_ENTER_DEPOSIT_FEE'),
                      },
                    ]}
                  >
                    <CustomInputNumber
                      width='360px'
                      percent={true}
                      decimalSeparator={decimalSeparator()}
                      precision={2}
                      className='custom-inputnumber'
                      min={0}
                      step={0.01}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label={t('ADVISORY_FEE')}
                    name="advisory_fee"
                    required={false}
                    rules={[
                      {
                        required: true,
                        message: t('PLEASE_ENTER_ADVISORY_FEE'),
                      },
                    ]}
                  >
                    <CustomInputNumber
                      width='360px'
                      percent={true}
                      decimalSeparator={decimalSeparator()}
                      precision={2}
                      className='custom-inputnumber'
                      min={0}
                      step={0.01}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Form.Item
                label={t('PORTFOLIO_TYPE')}
                name="portfolio_type"
                required={false}
                rules={[
                  {
                    required: true,
                    message: t('CHOOSE_PORTFOLIO_TYPE'),
                  },
                ]}
              >
                <CustomSelect
                  className="custom-select"
                  onChange={handleChangeType}
                >
                  <Select.Option value="1">
                    {t('PORTFOLIO_TYPE_ONE')}
                  </Select.Option>
                  <Select.Option value="2">
                    {t('PORTFOLIO_TYPE_TWO')}
                  </Select.Option>
                  <Select.Option value="3">
                    {t('PORTFOLIO_TYPE_THREE')}
                  </Select.Option>
                </CustomSelect>
              </Form.Item>
            </Form>
          </Container>
        </div>
        <ModalUnsaveData
          open={modalCancelVisible}
          closeModal={closeModal}
          cancelWithoutChange={cancelWithoutChange}
          onSubmit={handleSubmit}
        />
        <MainFooter/>
      </>
    )
  );
};

export default PortfolioEditInf;

PortfolioEditInf.propTypes = {
  userId: PropTypes.string,
  portfolioId: PropTypes.string,
  needSave: PropTypes.bool,
  setNeedSave: PropTypes.func,
  saveAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
};
