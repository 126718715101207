import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { Table } from 'antd';
import { twoDecimalWithSpaces } from '../../helpers/formatFractional';

const TableBuySell = ({ dataBuySell, isLoading }) => {
  const { t } = useTranslation('');

  const tableData = useMemo(() => {
    if (dataBuySell) {
      const data = [...dataBuySell];
      const summ = data?.reduce((acc, item) => acc + item?.value, 0);
      return data?.map(i => {
        return { ...i, share: i.value / summ * 100 };
      });
    } return [];
  }, [dataBuySell]);

  const columnsBuySell = [
    {
      title: t('INSTRUMENT'),
      dataIndex: 'instrument',
      align: 'left',
      width: '30%',
    },
    {
      title: t('ISIN'),
      dataIndex: 'isin',
      align: 'left',
    },
    {
      title: t('VALUE'),
      dataIndex: 'value',
      align: 'right',
      render: (value) => {
        return value
          ? `${twoDecimalWithSpaces(value)} kr`
          : '';
      },
    },
    {
      title: t('SHARE_PR'),
      dataIndex: 'share',
      align: 'right',
      render: (value) => value ? twoDecimalWithSpaces(value) : '',
    },
  ];
  return (
    <div >
      <Table
        rowKey={() => uuidv4()}
        rowClassName={(_, index) =>
          index % 2 === 0 ? 'editable-row' : 'editable-row is-odd'
        }
        columns={columnsBuySell}
        dataSource={tableData}
        loading={isLoading}
        size="middle"
        bordered
        pagination={false}
      />
    </div>
  );
};

export default TableBuySell;

TableBuySell.propTypes = {
  dataBuySell: PropTypes.array,
  isLoading: PropTypes.bool,
};
