import React, { useRef } from 'react';

import PropTypes from 'prop-types';

import { Select } from 'antd';
import { ReactComponent as SelectIcon } from '../../assets/select-input-icon.svg';

import './style.scss';
// const { Option } = Select;

const CustomSelect = (props) => {
  const { width = '360px' } = props;
  const selectRef = useRef();

  const Drop = (menu) => {
    return (
    <div className='custom-dropmenu-wrapper'>
      {menu}
    </div>
    );
  };

  return (
    <div
      ref={selectRef}
    >

    <Select
      style={{ width, backgroundColor: '#ffffff' }}
      {...props}
      dropdownRender={Drop}
      dropdownStyle={{ width }}
      suffixIcon={<SelectIcon/>}
      getPopupContainer={() => selectRef.current}
    />
    </div>
  );
};

export default CustomSelect;

CustomSelect.propTypes = {
  width: PropTypes.node,
};
