/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Button, Col, Form, Input, Row, Select, Spin } from 'antd';

import Container from '../../components/Container';

import {
  getPortfolioById,
  getStepOptimisationPortfolio,
  postStepOptimisationPortfolio,
  searchParams,
} from '../../data/store/portfolio/portfolioActions';
import { decimalSeparator } from '../../helpers/decimalSeparator';
import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';
import { ReactComponent as Pluse } from '../../assets/pluse-black-icon.svg';
import { getAssets, getCurrency, getInstrumentsAll } from '../../data/store/instruments/instrumentsActions';
import isEqual from 'lodash/isEqual';

import './style.scss';
import CustomInputNumber from '../../components/CustomInputNumber/CustomInputNumber';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import MainFooter from '../../components/MainFooter';
import ModalUnsaveData from '../../components/ModalUnsaveData/ModalUnsaveData';
import { numberWithSpaces } from '../../helpers/formatInteger';
import PortfolioFixationItem from './PortfolioFixationItem';
import ExcludeHoldings from '../../components/ExcludeHoldings/ExcludeHoldings';
import ExcludeAssetClassItem from './ExcludeAssetClassItem';

const PortfolioConstraints = ({ userId, portfolioId, needSave, setNeedSave, saveAlert, setSaveAlert }) => {
  const { t } = useTranslation('');
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  const [dataExclude, setDataExclude] = useState(null);

  const maximumCash = Form.useWatch('min_cash', form);

  const initValue = {
    ID: '',
    financialInstrumentID: null,
    instrumentName: '',
    financialAssetID: '',
    financialAssetName: '- - - - - - - - - ',
    unitsNumber: 0,
    fixed: 0,
    latest_value_sek: 0,
  };

  const [instruments, setInstruments] = useState([]);
  const [selectedAssetClass, setSelectedAssetClass] = useState([]);
  const [selectedInstruments, setSelectedInstruments] = useState([]);
  const [modalCancelVisible, setModalCancelVisible] = useState(false);

  const cashesList = useSelector(state => state.instrumentsReducer.cashes.list);
  const { assets } = useSelector((state) => state.instrumentsReducer);
  const searchList = useSelector((state) => state.portfolioReducer.instruments.list);
  const searchLoading = useSelector((state) => state.portfolioReducer.isLoading);
  const { portfolioById, bias, fixations, metadata } = useSelector(
    (state) => state.portfolioReducer
  );

  const { portfolioContent, unlistedContent } = portfolioById;
  const instrumentsForExclude = portfolioContent?.concat(unlistedContent) || [];

  const reinitValue = useMemo(() => {
    const { portfolioCashes, portfolioContent, unlistedContent } = portfolioById;
    const allInstruments = portfolioContent?.concat(portfolioCashes, unlistedContent);
    if (!portfolioById) return [];

    const casheInstr = (id) => {
      const cash = cashesList?.find(i => i.ID === id);
      return ({
        name: cash?.name,
        financialAssetID: cash?.financialAssetID,
        financialAssetName: cash?.financialAsset?.name,
      });
    };
    const getInstrument = (id, instrument_id) => {
      const findInstr = allInstruments?.find(i => i.ID === id);
      return ({
        name: findInstr?.instrumentName ?? findInstr?.name ?? casheInstr(instrument_id)?.name,
        financialAssetID: findInstr?.financialAssetID ?? casheInstr(instrument_id)?.financialAssetID,
        financialAssetName: findInstr?.financialAssetName ?? findInstr?.financialAsset?.name ?? casheInstr(instrument_id)?.financialAssetName,
        latest_value_sek: findInstr?.latest_value_sek
      });
    };
    const newFix = fixations?.map(i => {
      return ({
        key: i.internal_id,
        ID: i.internal_id,
        financialInstrumentID: i.instrument_id,
        instrumentName: getInstrument(i.internal_id, i.instrument_id)?.name,
        financialAssetID: getInstrument(i.internal_id, i.instrument_id)?.financialAssetID,
        financialAssetName: getInstrument(i.internal_id, i.instrument_id)?.financialAssetName,
        latest_value_sek: getInstrument(i.internal_id, i.instrument_id)?.latest_value_sek,
        unitsNumber: i.unitsNumber,
        fixed: i.fixed,
      });
    });
    return newFix;
  }, [fixations, cashesList, portfolioById]);

  useEffect(() => {
    saveAlert && saveAlert.flag && setModalCancelVisible(true);
  }, [saveAlert]);

  const getNameAsset = (id) => assets.find(i => i.ID === id)?.name ?? 'Error asset class!';

  useEffect(() => {
    form.setFieldValue('min_cash', metadata?.min_cash);
    if (metadata?.exclude_instrument) {
      setDataExclude(metadata?.exclude_instrument.map(i => ({ key: i, ID: i })));
    } else {
      setDataExclude([]);
    }
    if (metadata?.exclude_asset_class) {
      setSelectedAssetClass(metadata?.exclude_asset_class.map(i => ({ key: i, ID: i, label: getNameAsset(i) })));
    } else {
      setSelectedAssetClass([]);
    }
  }, [metadata]);

  useEffect(() => {
    if (bias) {
      form.setFieldValue('investor_bias', bias);
    }
  }, [bias]);

  useEffect(() => {
    setSelectedInstruments(reinitValue);
  }, [fixations, cashesList, portfolioById]);

  const totalCashes = useMemo(() => {
    let persent = 0;
    if (Object.keys(portfolioById || {})?.length && portfolioById?.total_value) {
      if (maximumCash > 0 && maximumCash <= 100) {
        persent = maximumCash;
      };

      return Math.round(portfolioById?.total_value * persent) / 100;
    } else {
      return 0;
    };
  }, [portfolioById, maximumCash]);

  const textSort = (a, b, direction = 1) => {
    if (a?.toLowerCase() < b?.toLowerCase()) { return -1 * direction; }
    if (a?.toLowerCase() > b?.toLowerCase()) { return 1 * direction; }
    return 0;
  };

  useEffect(() => {
    dispatch(getStepOptimisationPortfolio({ portfolioId }));
    dispatch(searchParams('', true));
    dispatch(getAssets());
    dispatch(getCurrency());
    if (!portfolioById?.length) dispatch(getPortfolioById({ portfolioId }));
    if (!cashesList?.length) dispatch(getInstrumentsAll({ limit: -1, offset: 0, type: 'cashes-only' })); ;
  }, []);

  const initValues = {
    name: '',
    percentage: '',
    instrument_id: '',
  };

  const debounceFetcher = (value) => {
    dispatch(searchParams(value || '', true));
  };

  const getField = () => {
    const formData = form.getFieldValue('investor_bias');
    const newData = formData.map((i) => {
      return Number(i.percentage);
    });
    return newData?.reduce((acc, el) => acc + el, 0);
  };

  // const handleChange = (changedFields, allFields) => {
  //   if (changedFields?.length) {
  //     const findValue = (name) => {
  //       const item = allFields.find(
  //         (i) => i.name.includes(name) && i.name.length !== 3
  //       );
  //       return item;
  //     };
  //     console.log('🚀 ~ ~ findValue:', findValue());
  //   }
  // };

  const needUpdateFormData = (allValues) => {
    const data = selectedInstruments
      .map(i => {
        const { ID, financialInstrumentID, unitsNumber, fixed } = i;
        return (
          {
            instrument_id: financialInstrumentID,
            internal_id: ID,
            unitsNumber,
            fixed,
          }
        );
      });
    const { investor_bias } = allValues;
    let min_cash = allValues?.min_cash;
    if (min_cash === null) min_cash = undefined;
    const excludeList = dataExclude?.map(i => i.ID);
    const excludeAssetsList = selectedAssetClass?.map(i => i.ID);

    const isNeedSave = !(isEqual(investor_bias, bias) &&
    isEqual(min_cash, metadata?.min_cash) &&
    (isEqual(dataExclude?.length ? dataExclude?.map(i => i.ID) : undefined, metadata?.exclude_instrument) ||
    isEqual(excludeList, metadata?.exclude_instrument)) &&
    (isEqual(selectedAssetClass?.length ? selectedAssetClass?.map(i => i.ID) : undefined, metadata?.exclude_asset_class) ||
    isEqual(excludeAssetsList, metadata?.exclude_asset_class)) &&
    isEqual(data, fixations));
    const isValid = (investor_bias?.length ? investor_bias?.every(i => i?.instrument_id && i?.name && i?.percentage) : true) &&
    (data?.length ? data?.every(i => i?.instrument_id && i?.fixed) : true) &&
    (dataExclude?.length ? dataExclude?.every(i => i?.ID) : true) &&
    (selectedAssetClass?.length ? selectedAssetClass?.every(i => i?.ID) : true);
    setNeedSave(isNeedSave && isValid);
  };

  const handleValueChange = (_, allValues) => {
    needUpdateFormData(allValues);
  };

  const handleChangeFixation = () => {
    const allValues = form.getFieldsValue();
    needUpdateFormData(allValues);
  };

  useEffect(() => {
    selectedInstruments && handleChangeFixation();
  }, [selectedInstruments]);

  useEffect(() => {
    selectedAssetClass && handleChangeFixation();
  }, [selectedAssetClass]);

  useEffect(() => {
    dataExclude && handleChangeFixation();
  }, [dataExclude]);

  const closeModal = () => {
    setModalCancelVisible(false);
  };

  const cancelWithoutChange = () => {
    const path = saveAlert.path;
    setNeedSave(false);
    dispatch(getStepOptimisationPortfolio({ portfolioId }));
    setSaveAlert({ flag: false, path: '' });
    closeModal();
    history.push(path);
  };

  useEffect(() => {
    if (Object.keys(portfolioById)?.length) {
      const newListedData = portfolioById?.portfolioContent.map((i) => {
        return {
          ID: i?.ID,
          financialInstrumentID: i.financialInstrumentID,
          instrumentName: i.instrumentName,
          financialAssetID: i.financialAssetID,
          financialAssetName: i.financialAsset?.name,
          unitsNumber: i.unitsNumber,
          fixed: 0,
          latest_value_sek: i?.latest_value_sek,
        };
      })?.filter(i => !!i?.financialAssetID);
      const newUnlistedData = portfolioById?.unlistedContent.map((i) => {
        return {
          ID: i?.ID,
          financialInstrumentID: i.financialInstrumentID,
          instrumentName: i.name,
          financialAssetID: i.financialAssetID,
          financialAssetName: i.financialAsset.name,
          unitsNumber: i.unitsNumber,
          fixed: 0,
          latest_value_sek: i?.latest_value_sek,
        };
      });
      const selectedKey = selectedInstruments.map(i => i?.ID);
      const arrayForSelect = newListedData.concat(newUnlistedData).filter(i => !selectedKey.includes(i?.ID));
      setInstruments(arrayForSelect);
    }
  }, [portfolioById]);

  const addInstrument = () => {
    const needAdd = selectedInstruments.every(i => i.ID && i.instrumentName);
    if (needAdd) {
      setSelectedInstruments([...selectedInstruments, { ...initValue, key: Date.now() }]);
    }
  };

  const addAssetClass = () => {
    const needAdd = selectedAssetClass.every(i => i.ID);
    if (needAdd) {
      setSelectedAssetClass([...selectedAssetClass, { ID: '', key: Date.now() }]);
    }
  };

  const handleChangeFix = (value, key) => {
    const newSelect = selectedInstruments.map(i => i.key === key ? { ...i, fixed: value } : i);
    setSelectedInstruments(newSelect);
  };

  const renderSelectOption = useMemo(() => {
    const render = instruments.map(el => {
      return (
        {
          key: el.ID,
          value: el?.ID,
          label: el?.instrumentName
        }
      );
    });
    const list = render.filter(i => !selectedInstruments?.some(el => el?.ID === i.value));
    const sortList = list?.sort((a, b) => textSort(a.label, b.label));
    return sortList;
  }, [instruments, selectedInstruments]);

  const renderAssetOption = useMemo(() => {
    const render = assets.map(el => {
      return (
        {
          key: el.ID,
          value: el?.ID,
          label: el?.name
        }
      );
    });
    const list = render.filter(i => !selectedAssetClass?.some(el => el?.ID === i.value));
    const sortedList = list?.sort((a, b) => textSort(a.label, b.label));
    return sortedList;
  }, [assets, selectedAssetClass]);

  const handleSelect = (data, key) => {
    const instrument = instruments?.find(i => i.ID === data.value);
    const newData = {
      key,
      ID: data.value,
      financialInstrumentID: instrument?.financialInstrumentID,
      instrumentName: instrument?.instrumentName,
      financialAssetID: instrument?.financialAssetID,
      financialAssetName: instrument?.financialAssetName,
      latest_value_sek: instrument?.latest_value_sek,
      unitsNumber: instrument?.unitsNumber,
      fixed: 0,
    };
    const newSelect = selectedInstruments.map(i => i.key === key ? newData : i);
    setSelectedInstruments(newSelect);
  };

  const handleSelectAssetClass = (data, key) => {
    const newData = {
      key,
      ID: data.value,
    };
    const newSelect = selectedAssetClass.map(i => i.key === key ? newData : i);
    setSelectedAssetClass(newSelect);
  };

  const handleDelete = (key) => {
    const newSelect = selectedInstruments.filter(i => i.key !== key);
    setSelectedInstruments(newSelect);
  };

  const handleAssetDelete = (key) => {
    const newSelect = selectedAssetClass.filter(i => i.key !== key);
    setSelectedAssetClass(newSelect);
  };

  const handleDeleteExclude = (key) => {
    const newSelect = dataExclude.filter(i => i.key !== key);
    setDataExclude(newSelect);
  };

  const handleSubmit = () => {
    form.submit();
  };

  const onSubmit = (data) => {
    const fixations = selectedInstruments
      .map(i => {
        const { ID, financialInstrumentID, unitsNumber, fixed } = i;
        return (
          {
            instrument_id: financialInstrumentID,
            internal_id: ID,
            unitsNumber,
            fixed,
          }
        );
      });
    const excludeList = dataExclude.map(i => i.ID);
    const excludeAssetsList = selectedAssetClass.map(i => i.ID);
    if (Object.keys(data)?.length) {
      const newData = {
        metadata: {
          min_cash: data?.min_cash ? +data?.min_cash : 0,
          exclude_instrument: excludeList,
          exclude_asset_class: excludeAssetsList,
        },
        bias: data?.investor_bias ?? null,
        fixations,
      };
      dispatch(postStepOptimisationPortfolio({ portfolioId, data: newData, history, path: saveAlert.path }));
    }
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
    closeModal();
  };

  const handleReset = () => {
    form.setFieldValue('investor_bias', bias);
    form.setFieldValue('min_cash', metadata?.min_cash);
    setDataExclude(metadata?.exclude_instrument.map(i => ({ key: i, ID: i })) ?? []);
    setSelectedAssetClass(metadata?.exclude_asset_class.map(i => ({ key: i, ID: i, label: getNameAsset(i) })) ?? []);
    setSelectedInstruments(reinitValue);
    setNeedSave(false);
    setSaveAlert({ flag: false, path: '' });
  };

  return (
    <>
      <div className="top-btn-wrapper">
        <Button
          className="portfolio-details-btn portfolio-export-btn"
          onClick={handleReset}
        >
          {t('CANCEL')}
        </Button>
        <Button
          disabled={!needSave}
          onClick={handleSubmit}
          className="portfolio-details-btn btn-edit-save"
        >
          {t('SAVE')}
        </Button>
      </div>
      <div className="portfolio-title-wrapper">
        <h2 className="portfolio-container-title">{t('CONSTRAINTS')}</h2>
      </div>
      <Form
        form={form}
        className="portfolio-constraints"
        // onFieldsChange={(changedFields, allFields) =>
        //   handleChange(changedFields, allFields)
        // }
        onValuesChange={handleValueChange}
        onFinish={onSubmit}
      >
        <Container widthP={100} mb='24px' padding='0'>
          <Row className="header-wrapper bias-header-wrapper">
            <h2 className="header-title bias-header-title">{t('CASH_MANAGEMENT')}</h2>
          </Row>
          <Row style={{ gap: '48px', padding: '0 24px 48px 24px' }}>
            <Col>
              <Form.Item
                layout='vertical'
                className='custom-required'
                label={t('MINIMUM_CASH_LEVEL')}
                name='min_cash'
                required={false}
              >
                <CustomInputNumber
                  width={'360px'}
                  percent={true}
                  className='custom-inputnumber'
                  decimalSeparator={decimalSeparator()}
                  min={0}
                  max={100}
                  placeholder='0,00'
                />
              </Form.Item>

            </Col>
            <Col>
              <div className='currency-sek-wrapper'>
                <label className='currency-sek-label'>{t('CURRENCY')}</label>
                <p className='currency-sek-text'>SEK</p>
              </div>
            </Col>
            <Col>
              <div className='currency-sek-wrapper'>
                <label className='currency-sek-label'>{t('CASH_AMOUNT')}</label>
                <p className='currency-sek-text'>{numberWithSpaces(totalCashes)}</p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container widthP={100} mb='24px' padding='0'>
          <Row className="header-wrapper bias-header-wrapper">
            <h2 className="header-title bias-header-title">{t('INVESTOR_BIAS')}</h2>
          </Row>
          <div className='grey-wrapper'>
            <Row className="table-header-wrapper">
              <Col className="constraints-table-header" span={9}>
                {t('DESCRIPTION')}
              </Col>
              <Col className="constraints-table-header" span={9}>
                {t('PRODUCT_NAME')}
              </Col>
              <Col className="constraints-table-header" span={4}>
                {t('PERCENTAGE')}, %
              </Col>
              <Col className="constraints-table-header" span={2}></Col>
            </Row>
          </div>
          <Form.List name="investor_bias">
            {(fields, { add, remove }) => (
              <div className='investor_bias-wrapper'>
                {fields.map(({ key, name, ...restField }) => (
                  <div className='bias-row-wrapper' key={key}>
                  <Row
                    className="bias-form-row"
                    gutter={32}
                  >
                    <Col span={9}>
                      <Form.Item {...restField} name={[name, 'name']}>
                        <Input
                          className='custom-input'
                          placeholder={t('ENETR_BIAS_NAME')}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={9}>
                      <Form.Item
                        {...restField}
                        name={[name, 'instrument_id']}
                        validateTrigger={['onBlur', 'onChange']}
                        rules={[
                          {
                            required: true,
                            message: 'Choose product name',
                          },
                        ]}
                      >
                        <CustomSelect
                          showSearch
                          onSearch={(value) => debounceFetcher(value)}
                          onBlur={() => debounceFetcher()}
                          notFoundContent={searchLoading ? <Spin size="small" /> : null}
                          placeholder={t('PRODUCT_NAME_ISIN')}
                          className="custom-select"
                          filterOption={false}
                        >
                            {
                              searchList?.length > 0 &&
                              searchList
                                .slice()
                                .sort((a, b) => textSort(a.name, b.name))
                                .map(({ ID, isin, name }) => (
                                  <Select.Option key={ID} value={ID}>
                                    {`${name} - ${isin}`}
                                  </Select.Option>
                                ))
                            }
                        </CustomSelect>
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        {...restField}
                        name={[name, 'percentage']}
                        validateTrigger="onChange"
                        rules={[
                          {
                            required: true,
                            message: 'Enter percentage',
                          },
                          () => ({
                            validator (_, value) {
                              getField(form.getFieldValue('investor_bias'));
                              if (!value) {
                                // eslint-disable-next-line prefer-promise-reject-errors
                                return Promise.reject();
                              }
                              if (getField() > 25) {
                                // eslint-disable-next-line prefer-promise-reject-errors
                                return Promise.reject(
                                  'The maximum value for the sum of all the percentages is 25 %'
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <CustomInputNumber
                          decimalSeparator={decimalSeparator()}
                          precision={2}
                          className='custom-inputnumber bias-percentage'
                          percent={true}
                          min={0.01}
                          step={0.01}
                          controls={false}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2}>
                    <div className='del-btn-wrapper'>
                      <Button
                        type='text'
                        style={{ marginTop: '10px', cursor: 'pointer' }}
                        icon={<Delete />}
                        onClick={() => {
                          remove(name);
                        }}
                      />
                    </div>
                    </Col>
                  </Row>
                  </div>
                ))}
                <Row className='add-btn-wrapper' justify={'end'}>
                  <Button
                    type='text'
                    className="holdings-add-btn"
                    onClick={() => add(initValues)}
                    icon={<Pluse />}
                    iconPosition={'end'}
                  >{t('ADD')}</Button>
                </Row>
              </div>
            )}
          </Form.List>
        </Container>
        {/* <Container widthP={100} mb='24px' padding='0'>
          <Row className="header-wrapper bias-header-wrapper">
            <h2 className="header-title bias-header-title">{t('MIN_MAX')}</h2>
          </Row>
        <div className='grey-wrapper'>
          <Row className="table-header-wrapper">
            <Col className="constraints-table-header" span={12}>
              {t('ASSET_CLASS')}
            </Col>
            <Col className="constraints-table-header" span={5}>
              {t('MIN')}, %
            </Col>
            <Col className="constraints-table-header" span={5}>
              {t('MAX')}, %
            </Col>
            <Col className="constraints-table-header" span={2}></Col>
          </Row>
        </div>
          <Form.List name="constraints">
            {(fields, { add, remove }) => (
              <div className='investor_bias-wrapper'>
                {fields.map(({ key, name, ...restField }) => (
                  <div className='bias-row-wrapper' key={key}>
                    <Row
                      gutter={32}
                      className="bias-form-row"
                    >
                      <Col span={12}>
                        <Form.Item
                          {...restField}
                          name={[name, 'asset_id']}
                          validateTrigger={['onBlur', 'onChange']}
                          rules={[
                            {
                              required: true,
                              message: 'Choose asset name',
                            },
                          ]}
                        >
                          <CustomSelect
                            placeholder="Choose alternative"
                            className="custom-select"
                            options={getAssetList}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item
                          {...restField}
                          name={[name, 'min']}
                          validateTrigger="onChange"
                          rules={[
                            {
                              required: true,
                              message: 'Enter min',
                            },
                          ]}
                        >
                          <CustomInputNumber
                            percent={true}
                            className='custom-inputnumber minmax-input'
                            min={0}
                            max={99}
                            step={1}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item
                          {...restField}
                          name={[name, 'max']}
                          validateTrigger="onChange"
                          rules={[
                            {
                              required: true,
                              message: 'Enter max',
                            },
                          ]}
                        >
                          <CustomInputNumber
                            percent={true}
                            className='custom-inputnumber minmax-input'
                            min={1}
                            max={100}
                            step={1}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                      <div className='del-btn-wrapper'>
                        <Button
                          type='text'
                          style={{ marginTop: '10px', cursor: 'pointer' }}
                          icon={<Delete />}
                          onClick={() => {
                            remove(name);
                          }}
                        />
                      </div>
                      </Col>
                    </Row>
                  </div>
                ))}
                <Row className='add-btn-wrapper' justify={'end'}>
                  <Button
                    type='text'
                    className="holdings-add-btn"
                    onClick={() => add(initValuesConstant)}
                    icon={<Pluse />}
                    iconPosition={'end'}
                  >{t('ADD')}</Button>
                </Row>
              </div>
            )}
          </Form.List>
        </Container> */}
        <Container widthP={100} mb='48px' padding='0 0 24px'>
          <Row className="header-wrapper fixation-header-wrapper">
            <h2 className="header-title fixation-header-title">{t('FIXATION')}</h2>
          </Row>
          <div className='grey-wrapper'>
            <Row className="table-header-wrapper">
              <Col style={{ flexBasis: '20%' }} className='fixation-table-header'>
                {t('ASSET_CLASS')}
              </Col>
              <Col style={{ flexBasis: '29.16%' }} className='fixation-table-header'>
                {t('INSTRUMENTS')}
              </Col>
              <Col className='fixation-table-header' style={{ textAlign: 'center', flexBasis: '12.33%' }}>
                {t('NO_SHARES')}
              </Col>
              <Col style={{ flexBasis: '13.85%' }} className='fixation-table-header'>
                {t('NO_SHARES_TO_FIX')}
              </Col>
              <Col className='fixation-table-header' style={{ textAlign: 'center', flexBasis: '6.33%' }}>
                {t('FIX')}
              </Col>
              <Col className='fixation-table-header' style={{ textAlign: 'center', flexBasis: '12%' }}>
                {t('VALUE')}
              </Col>
              <Col style={{ flexBasis: '6.33%' }}>
              {' '}
              </Col>
            </Row>
          </div>
          <div className='investor_bias-wrapper'>
            {selectedInstruments?.map((i) => {
              return (
                <PortfolioFixationItem
                  key={i.key}
                  i={i}
                  selectedInstruments={selectedInstruments}
                  setSelectedInstruments={setSelectedInstruments}
                  handleSelect={handleSelect}
                  renderSelectOption={renderSelectOption}
                  handleChangeFix={handleChangeFix}
                  handleDelete={handleDelete}
                  cashesList={cashesList}
                />
              );
            })
            }

            <Row className='add-btn-wrapper' justify={'end'}>
              <Button
                type='text'
                className="holdings-add-btn"
                onClick={() => addInstrument()}
                icon={<Pluse />}
                iconPosition={'end'}
              >{t('ADD')}</Button>
            </Row>

          </div>
        </Container>
        <Container widthP={100} mb='48px' padding='0 0 24px'>
          <Row className="header-wrapper fixation-header-wrapper">
            <h2 className="header-title fixation-header-title">{t('EXCLUDE_HOLDINGS')}</h2>
          </Row>
          <ExcludeHoldings
            data={dataExclude}
            setData={setDataExclude}
            instrumentsForExclude={instrumentsForExclude}
            handleDelete={handleDeleteExclude}
          />
        </Container>
        <Container widthP={100} mb='48px' padding='0 0 24px'>
          <Row className="header-wrapper fixation-header-wrapper">
            <h2 className="header-title fixation-header-title">{t('EXCLUDE_ASSET_CLASS')}</h2>
          </Row>
          <div className='investor_bias-wrapper'>
            {selectedAssetClass?.map((i) => {
              return (
                <ExcludeAssetClassItem
                  key={i.key}
                  i={i}
                  selectedAssetClass={selectedAssetClass}
                  setSelectedInstruments={setSelectedAssetClass}
                  handleSelect={handleSelectAssetClass}
                  renderSelectOption={renderAssetOption}
                  handleDelete={handleAssetDelete}
                />
              );
            })
            }

            <Row className='add-btn-wrapper' justify={'end'}>
              <Button
                type='text'
                className="holdings-add-btn"
                onClick={() => addAssetClass()}
                icon={<Pluse />}
                iconPosition={'end'}
              >{t('ADD')}</Button>
            </Row>

          </div>
        </Container>
      </Form>
      <ModalUnsaveData
        open={modalCancelVisible}
        closeModal={closeModal}
        cancelWithoutChange={cancelWithoutChange}
        onSubmit={handleSubmit}
      />
      <MainFooter />
    </>
  );
};

export default PortfolioConstraints;

PortfolioConstraints.propTypes = {
  userId: PropTypes.string,
  portfolioId: PropTypes.string,
  needSave: PropTypes.bool,
  setNeedSave: PropTypes.func,
  saveAlert: PropTypes.object,
  setSaveAlert: PropTypes.func,
};
