export const OnboardingEN = {
  WELCOME: 'Welcome to Citroneer!',
  WHAT_TYPE_OF_ACCOUNT: 'What type of account do you want to open?',
  WANT_TO_START_A_PERSONAL:
    'Do you want to start a personal account or a business account?',
  PRIVATE_CUSTOMER: 'Private client',
  BUSINESS_CUSTOMER: 'Corporate client',
  PRIVAT_ORGANISATION_NUMBER: 'Social security/organisation number',
  PRIVAT_NUMBER: 'Social security number',
  ORGANISATION_NUMBER: 'Organisation number',
  ENTER_ORGANIZATION_NUMBER: 'Enter social security/organization number',
  MOVE_ON: 'Move on',
  RFA19: 'Would you like to identify yourself or sign with a BankID on this computer or with a Mobile BankID?',
  MOBILEID_HEADER: 'Start the BankID app on your phone or tablet. Tap the QR code button in the app. Point the camera at the QR code below.',
  USE_BANKID: 'Use BankID on this device',
  THE_LOGIN_FAILED: 'The login failed',
  LOGIN_FAILED_TEXT: 'Failed to scan the QR code. Start the BankID app and scan the QR code. Make sure that the BankID app is up to date. If you don\'t have the BankID app, you need to install it and order a BankID from your internet bank. Install the app from your app store or https://install.bankid.com.',
  TRY_AGAIN: 'Try again',
  LOGIN_WITH_BANKID: 'Log in with Test BankID',
  TYPE_YOUR_SECURITY: 'Type your security code in the BankID app and select Identify.',
};
