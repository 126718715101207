import {
  takeEvery, takeLeading,
  takeLatest
} from '@redux-saga/core/effects';
import {
  LOGIN_USER,
  FORGOT_PASSWORD,
  RESET_USER_PASSWORD,
  SIGN_UP_USER,
  LOG_OUT,
  CONFIRM_AUTH,
  CHANGE_EMAIL,
  USER_PROFILE_UPDATE,
  GET_ADVISOR_BY_ID,
  UPDATE_ADVISOR_EMAIL,
  UPDATE_ADVISOR_NAME,
  UPDATE_ADVISOR_PHONE,
  UPDATE_ADVISOR_PASSWORD, RESEND_EMAIL, GET_QR_BANKID, CLEAR_QR_BANKID, COLLECT_BANKID, GET_QR_SIGN_BANKID, CONFIRM_EMAIL, GET_TWOFACTOR_SECRET, GET_TWOFACTOR_CHECK, GET_QR_ATTACH_BANKID,
  GET_USER_BY_ID
} from './user/userActionTypes';
import * as userSaga from './user/userSaga';
import * as clientSaga from './clients/clientSaga';
import * as portfolioSaga from './portfolio/portfolioSaga';
import * as instrumentsSaga from './instruments/instrumentsSaga';
import * as advisorSaga from './advisors/advisorSaga';
import {
  CREATE_CLIENT_ACCOUNT,
  GET_USERS,
  SET_CLIENT_TYPE,
  GET_CLIENT,
  SET_CLIENT_INFORMATION,
  UPDATE_CLIENT_INFO,
  DELETE_CLIENT_PHONE,
  UPDATE_CLIENT_PHONE,
  SET_CLIENT_PHONE,
  SET_CLIENT_DATA,
  GET_CLIENT_DATA,
  SEND_INVITATION,
  GET_INVITATION_INFO,
  ACCEPT_INVITATION,
  SET_CLIENT_RISK_LEVEL,
  SEND_CLIENT_APPROVE,
  SET_CLIENT_FEES,
  GET_CLIENT_FEES,
  SET_CLIENT_SCORE,
  SET_CLIENT_ADDRESS,
  ADD_REPRESENTATIVE,
  UPDATE_REPRESENTATIVE,
  DELETE_REPRESENTATIVE,
  ADD_REPRESENTATIVE_DOC,
  DELETE_CLIENT,
  CREATE_FINPLAN,
  GET_FINPLAN,
  PATCH_FINPLAN,
  DELETE_FINPLAN,
  GET_SUGGESTIONS_PORTFOLIO,
  APPROVE_SUGGESTIONS_PORTFOLIO,
  DELETE_SUGGESTIONS_PORTFOLIO,
  GET_APPROVED_PORTFOLIO,
  GET_ALL_APPROVED_PORTFOLIO,
  GET_CANDIDATE_TRANSACTIONS,
  EXECUTE_PORTFOLIO
} from './clients/clientActionsTypes';
import {
  CREATE_PORTFOLIO_TASK_ANALYSIS,
  CREATE_PORTFOLIO_TASK_PROSPECTING,
  CREATE_REPORT,
  DELETE_PORTFOLIO_BY_ID,
  DOWNLOAD_REPORT,
  DOWNLOAD_TEMP,
  EXPORT_PORTFOLIO,
  EXPORT_SELECTED_PORTFOLIO,
  GET_ALL_PORTFOLIO,
  GET_ALL_REPORTS,
  GET_CANDIDATES,
  GET_HISTORY_BY_PORTFOLIO_ID,
  GET_PORTFOLIO_BY_ID,
  GET_STEP_OPTIMISATION_PORTFOLIO,
  GET_TRANSACTIONS,
  IMPORT_PORTFOLIO, MAPPING_LINK_PORTFOLIO, OPTIMIZATION_PORTFOLIO,
  PORTFOLIO_FINAL,
  POST_STEP_OPTIMISATION_PORTFOLIO,
  SAVE_FULL_PORTFOLIO,
  SEARCH_PARAMS,
  SEND_DATA_PDF_PORTFOLIO,
  SEND_FINANCIAL_PLAN
} from './portfolio/portfolioActionTypes';
import {
  ASSET_REQUEST,
  CREATE_ASSET_CLASS,
  CREATE_INSTRUMENT,
  DELETE_ASSET_CLASS,
  DELETE_INSTRUMENT,
  DELETE_REQUEST,
  GET_ASSET_CATEGORIES,
  GET_ASSETS,
  GET_CURRENCY,
  GET_DIVERSIFICATION,
  GET_INSTRUMENTS_ALL, GET_INSTRUMENTS_TBD,
  MAPPING_REQUEST,
  UPDATE_ASSET_CLASS,
  UPDATE_FINANCIAL_ASSET,
  UPDATE_INSTRUMENT
} from './instruments/instrumentsActionTypes';
import {
  ACTIVATE_ADVISOR,
  CREATE_ADVISOR,
  DEACTIVATE_ADVISOR,
  DELETE_ADVISOR,
  GET_ADVISORS,
  GET_ADVISORS_AWAIT_APPROVE,
  REINVITE_ADVISOR,
  UPDATE_ADVISOR
} from './advisors/advisorActionTypes';

export function * rootSaga () {
  yield takeEvery(LOGIN_USER.ACTION, userSaga.login);
  yield takeEvery(FORGOT_PASSWORD.ACTION, userSaga.forgotPassword);
  yield takeEvery(CONFIRM_EMAIL.ACTION, userSaga.confirmEmail);
  yield takeEvery(RESET_USER_PASSWORD.ACTION, userSaga.resetUserPassword);
  yield takeEvery(SIGN_UP_USER, userSaga.signUp);
  yield takeEvery(CONFIRM_AUTH.ACTION, userSaga.confirmAuth);
  yield takeEvery(LOG_OUT, userSaga.logOut);
  yield takeEvery(CHANGE_EMAIL.ACTION, userSaga.changeEmail);
  yield takeEvery(RESEND_EMAIL.ACTION, userSaga.resendEmail);
  yield takeEvery(USER_PROFILE_UPDATE.ACTION, userSaga.userProfileUpdate);
  yield takeEvery(GET_ADVISOR_BY_ID.ACTION, userSaga.getAdvisorById);
  yield takeEvery(UPDATE_ADVISOR_EMAIL.ACTION, userSaga.updateAdvisorEmail);
  yield takeEvery(UPDATE_ADVISOR_NAME.ACTION, userSaga.updateAdvisorName);
  yield takeEvery(UPDATE_ADVISOR_PHONE.ACTION, userSaga.updateAdvisorPhone);
  yield takeEvery(UPDATE_ADVISOR_PASSWORD.ACTION, userSaga.updateAdvisorPassword);
  yield takeLeading(GET_QR_BANKID.ACTION, userSaga.getQRCodeBankID);
  yield takeLeading(GET_QR_SIGN_BANKID.ACTION, userSaga.getQRCodeSignBankID);
  yield takeLeading(GET_QR_ATTACH_BANKID.ACTION, userSaga.getQRCodeAttachBankID);
  yield takeEvery(CLEAR_QR_BANKID.ACTION, userSaga.clearQRCodeBankID);
  yield takeLeading(COLLECT_BANKID.ACTION, userSaga.collectBankID);
  yield takeLeading(GET_TWOFACTOR_SECRET.ACTION, userSaga.getTwoFactorSecret);
  yield takeLeading(GET_TWOFACTOR_CHECK.ACTION, userSaga.getTwoFactorSecretCheck);
  yield takeLeading(GET_USER_BY_ID.ACTION, userSaga.getUserById);

  yield takeEvery(GET_USERS.ACTION, clientSaga.getUsers);
  yield takeEvery(GET_CLIENT.ACTION, clientSaga.getClient);
  yield takeEvery(CREATE_CLIENT_ACCOUNT.ACTION, clientSaga.createClientAccount);
  yield takeEvery(UPDATE_CLIENT_INFO.ACTION, clientSaga.updateClientInfo);
  yield takeEvery(SET_CLIENT_ADDRESS.ACTION, clientSaga.updateClientAddress);
  yield takeEvery(SET_CLIENT_TYPE.ACTION, clientSaga.setClientType);
  yield takeEvery(SET_CLIENT_SCORE.ACTION, clientSaga.addClientScore);
  yield takeEvery(SET_CLIENT_PHONE.ACTION, clientSaga.addClientPhone);
  yield takeEvery(UPDATE_CLIENT_PHONE.ACTION, clientSaga.updateClientPhone);
  yield takeEvery(DELETE_CLIENT_PHONE.ACTION, clientSaga.deleteClientPhone);
  yield takeEvery(ADD_REPRESENTATIVE.ACTION, clientSaga.addRepresentative);
  yield takeEvery(UPDATE_REPRESENTATIVE.ACTION, clientSaga.updateRepresentative);
  yield takeEvery(DELETE_REPRESENTATIVE.ACTION, clientSaga.deleteRepresentative);
  yield takeEvery(ADD_REPRESENTATIVE_DOC.ACTION, clientSaga.addRepresentativeDoc);

  yield takeEvery(SET_CLIENT_INFORMATION.ACTION, clientSaga.setClientInformation);
  yield takeEvery(SET_CLIENT_DATA.ACTION, clientSaga.setClientData);
  yield takeEvery(GET_CLIENT_DATA.ACTION, clientSaga.getClientData);
  yield takeEvery(SEND_INVITATION.ACTION, clientSaga.sendInvitation);
  yield takeEvery(GET_INVITATION_INFO.ACTION, clientSaga.getInvitationInfo);
  yield takeEvery(ACCEPT_INVITATION.ACTION, clientSaga.acceptInvitation);
  yield takeEvery(SET_CLIENT_RISK_LEVEL.ACTION, clientSaga.setRiskLevel);
  yield takeEvery(SEND_CLIENT_APPROVE.ACTION, clientSaga.sendClientApprove);
  yield takeEvery(SET_CLIENT_FEES.ACTION, clientSaga.setClientFees);
  yield takeEvery(GET_CLIENT_FEES.ACTION, clientSaga.getClientFees);
  yield takeEvery(DELETE_CLIENT.ACTION, clientSaga.deleteClient);
  yield takeEvery(GET_FINPLAN.ACTION, clientSaga.getFinPlan);
  yield takeEvery(CREATE_FINPLAN.ACTION, clientSaga.createFinPlan);
  yield takeEvery(PATCH_FINPLAN.ACTION, clientSaga.patchFinPlan);
  yield takeEvery(DELETE_FINPLAN.ACTION, clientSaga.deleteFinPlan);
  yield takeEvery(GET_SUGGESTIONS_PORTFOLIO.ACTION, clientSaga.getSuggestionsPortfolio);
  yield takeEvery(APPROVE_SUGGESTIONS_PORTFOLIO.ACTION, clientSaga.approveSuggestions);
  yield takeEvery(DELETE_SUGGESTIONS_PORTFOLIO.ACTION, clientSaga.deleteSuggestions);
  yield takeEvery(GET_APPROVED_PORTFOLIO.ACTION, clientSaga.getApprovedPortfolio);
  yield takeEvery(GET_ALL_APPROVED_PORTFOLIO.ACTION, clientSaga.getAllApprovedPortfolio);
  yield takeEvery(GET_CANDIDATE_TRANSACTIONS.ACTION, clientSaga.getCandidatTransactions);
  yield takeEvery(EXECUTE_PORTFOLIO.ACTION, clientSaga.executeApprovedPortfolio);

  yield takeLatest(GET_ALL_PORTFOLIO.ACTION, portfolioSaga.getAllPortfolio);
  yield takeEvery(GET_PORTFOLIO_BY_ID.ACTION, portfolioSaga.getPortfolioById);
  yield takeEvery(SAVE_FULL_PORTFOLIO.ACTION, portfolioSaga.saveFullPortfolio);
  yield takeEvery(DELETE_PORTFOLIO_BY_ID.ACTION, portfolioSaga.deletePortfolio);
  yield takeEvery(SEARCH_PARAMS.ACTION, portfolioSaga.searchParams);
  yield takeEvery(EXPORT_PORTFOLIO.ACTION, portfolioSaga.exportPortfolio);
  yield takeEvery(EXPORT_SELECTED_PORTFOLIO.ACTION, portfolioSaga.exportSelectedPortfolio);
  yield takeEvery(IMPORT_PORTFOLIO.ACTION, portfolioSaga.importPortfolio);
  yield takeEvery(GET_ALL_REPORTS.ACTION, portfolioSaga.getAllReports);
  yield takeEvery(DOWNLOAD_REPORT.ACTION, portfolioSaga.downloadReport);
  yield takeEvery(DOWNLOAD_TEMP.ACTION, portfolioSaga.downloadTEMP);
  yield takeEvery(CREATE_REPORT.ACTION, portfolioSaga.createReport);
  yield takeEvery(GET_HISTORY_BY_PORTFOLIO_ID.ACTION, portfolioSaga.getHistoryByPortfolioId);
  yield takeEvery(OPTIMIZATION_PORTFOLIO.ACTION, portfolioSaga.optimizationRequest);
  yield takeEvery(CREATE_PORTFOLIO_TASK_ANALYSIS.ACTION, portfolioSaga.createPortfolioTaskAnalysis);
  yield takeEvery(CREATE_PORTFOLIO_TASK_PROSPECTING.ACTION, portfolioSaga.createPortfolioTaskProspecting);
  yield takeEvery(GET_STEP_OPTIMISATION_PORTFOLIO.ACTION, portfolioSaga.getStepOptimisationPortfolio);
  yield takeEvery(POST_STEP_OPTIMISATION_PORTFOLIO.ACTION, portfolioSaga.postStepOptimisationPortfolio);
  yield takeEvery(SEND_FINANCIAL_PLAN.ACTION, portfolioSaga.sendFinancialPlan);
  yield takeEvery(PORTFOLIO_FINAL.ACTION, portfolioSaga.portfolioFinalrequest);
  yield takeEvery(SEND_DATA_PDF_PORTFOLIO.ACTION, portfolioSaga.sendDataPDFportfolio);
  yield takeEvery(MAPPING_LINK_PORTFOLIO.ACTION, portfolioSaga.mappingEditPortfolio);
  yield takeEvery(GET_CANDIDATES.ACTION, portfolioSaga.getCandidates);
  yield takeEvery(GET_TRANSACTIONS.ACTION, portfolioSaga.getTransactions);

  yield takeEvery(GET_INSTRUMENTS_ALL.ACTION, instrumentsSaga.getInstrumentsAll);
  yield takeLatest(GET_INSTRUMENTS_TBD.ACTION, instrumentsSaga.getInstrumentsTBDAll);
  yield takeEvery(GET_ASSETS.ACTION, instrumentsSaga.getAssets);
  yield takeEvery(GET_ASSET_CATEGORIES.ACTION, instrumentsSaga.getAssetCategories);
  yield takeEvery(UPDATE_FINANCIAL_ASSET.ACTION, instrumentsSaga.updateFinancialAssets);
  yield takeEvery(UPDATE_INSTRUMENT.ACTION, instrumentsSaga.updateInstrument);
  yield takeEvery(CREATE_INSTRUMENT.ACTION, instrumentsSaga.createInstrument);
  yield takeEvery(DELETE_INSTRUMENT.ACTION, instrumentsSaga.deleteInstrument);
  yield takeEvery(ASSET_REQUEST.ACTION, instrumentsSaga.assetRequest);
  yield takeEvery(MAPPING_REQUEST.ACTION, instrumentsSaga.mappingRequest);
  yield takeEvery(DELETE_REQUEST.ACTION, instrumentsSaga.deleteRequest);
  yield takeEvery(GET_CURRENCY.ACTION, instrumentsSaga.getCurrency);
  yield takeEvery(GET_DIVERSIFICATION.ACTION, instrumentsSaga.getDiversification);
  yield takeEvery(CREATE_ASSET_CLASS.ACTION, instrumentsSaga.createAssetClass);
  yield takeEvery(DELETE_ASSET_CLASS.ACTION, instrumentsSaga.deleteAssetClass);
  yield takeEvery(UPDATE_ASSET_CLASS.ACTION, instrumentsSaga.updateAssetClass);

  yield takeEvery(GET_ADVISORS.ACTION, advisorSaga.getAdvisors);
  yield takeEvery(GET_ADVISORS_AWAIT_APPROVE.ACTION, advisorSaga.getAdvisorsAwaitApprove);
  yield takeEvery(ACTIVATE_ADVISOR.ACTION, advisorSaga.activateAdvisor);
  yield takeEvery(DEACTIVATE_ADVISOR.ACTION, advisorSaga.deactivateAdvisor);
  yield takeEvery(CREATE_ADVISOR.ACTION, advisorSaga.createAdvisor);
  yield takeEvery(UPDATE_ADVISOR.ACTION, advisorSaga.updateAdvisor);
  yield takeEvery(REINVITE_ADVISOR.ACTION, advisorSaga.reinviteAdvisor);
  yield takeEvery(DELETE_ADVISOR.ACTION, advisorSaga.deleteAdvisor);
}
