/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Table, Input } from 'antd';
import PropTypes from 'prop-types';
import Decimal from 'decimal.js';
import { useTranslation } from 'react-i18next';
import { numberWithSpaces } from '../../helpers/formatInteger';
import { colorsCategoryOld } from '../../helpers/colors';

import './styles.scss';
import { formatPercentage } from '../../helpers/formatPercentage';
import { decimalSeparator } from '../../helpers/decimalSeparator';

export const PortfolioTableOptimal = ({ tableColumns, setTableColumns, columns, optimalPortfolio }) => {
  const { t } = useTranslation('');

  const countCategory = useCallback(() => {
    const categoryArr = {};
    optimalPortfolio?.items?.forEach(el => {
      if (Object.keys(categoryArr).includes(el.financialAssetCategory.name)) {
        categoryArr[el.financialAssetCategory.name] += 1;
      } else {
        categoryArr[el.financialAssetCategory.name] = 1;
      }
    });
    return categoryArr;
  }, [optimalPortfolio]);

  const percentage = (value, isText) => {
    const percentage = Math.round(value * 100 / optimalPortfolio?.items?.map(i => i.value).reduce((acc, el) => acc + el, 0) * 100) / 100;
    return isText ? `${percentage} %` : percentage;
  };

  const data = optimalPortfolio?.items?.map((row, index) => {
    return {
      key: index,
      financialInstrumentName: row.asset_name,
      ISIN: row.isin,
      value: Math.round(row.value),
      valueText: numberWithSpaces(Math.round(row.value)) + ' kr',
      percentage: percentage(row.value),
      percentageText: percentage(row.value, true),
      fee: row.fee,
      feeText: row.fee + ' %',
      assetClass: row.financialAsset.name,
      financialAssetCategory: row.financialAssetCategory.name,
      categoryID: row.asset_class_id,
      weight: row.weight
    };
  }).sort((a, b) => a.financialAssetCategory > b.financialAssetCategory ? 1 : b.financialAssetCategory > a.financialAssetCategory ? -1 : 0);

  const portfolioTotal = useMemo(() => {
    const total = {
      value: 0,
      valueText: '',
      fee: 0,
      percentage: 0,
    };
    try {
      total.value = Math.round(optimalPortfolio.total_value);
      total.valueText = numberWithSpaces(total.value);
      total.fee = Math.round(optimalPortfolio.total_fee * 100) / 100;
    } catch (e) {
      return total;
    }
    return total;
  }, [data]);

  useEffect(() => {
    if (!columns) {
      const defaultColumns = [
        {
          title: t('INSTRUMENT'),
          dataIndex: 'financialInstrumentName',
          // sorter: (a, b) => {
          //   if (a.financialInstrumentName < b.financialInstrumentName) {
          //     return 1;
          //   } else if (a.financialInstrumentName > b.financialInstrumentName) {
          //     return -1;
          //   } else {
          //     return 0;
          //   }
          // },
        },
        {
          title: t('VALUE'),
          dataIndex: 'valueText',
          className: 'table-header-right',
          width: '10%',
          align: 'right',
          sorter: (a, b) => a.value - b.value,
        },
        {
          title: t('WEIGHT'),
          dataIndex: 'weight',
          className: 'table-header-right',
          width: '10%',
          align: 'right',
          render: (value) => {
            return formatPercentage(value) || '0.00 %';
          },
          sorter: (a, b) => a.weight - b.weight,
        },
        {
          title: t('FEE'),
          dataIndex: 'fee',
          className: 'holdings-fee table-header-center',
          width: '10%',
          align: 'center',
          render: (value) => {
            return (
              <p>
                {formatPercentage(value) ||
                `${'0.00'.replace('.', decimalSeparator())} %`}
              </p>
            );
          },
          sorter: (a, b) => a.fee - b.fee,
        },
        {
          title: t('ASSET_CLASS'),
          dataIndex: 'assetClass',
          width: '20%',
          sorter: (a, b) => {
            if (a.assetClass < b.assetClass) {
              return 1;
            } else if (a.assetClass > b.assetClass) {
              return -1;
            } else {
              return 0;
            }
          },
          render: (value, record) => {
            return (
              <>
                <p className="holdings-assetclass-cell-asset">{value}</p>
                <p className="holdings-assetclass-cell-category">
                  {record.financialAssetCategory}
                </p>
              </>
            );
          },
        },
      ];
      setTableColumns(defaultColumns);
    }
  }, [columns, optimalPortfolio]);

  return (
        <div className="portfolio-table sugg-portfolio">
          <Table
            rowClassName={(_, index) =>
              index % 2 === 0 ? 'editable-row padding' : 'editable-row padding is-odd'}
            columns={tableColumns}
            dataSource={data}
            size="middle"
            bordered
            pagination={false}
          />
        </div>
  );
};

PortfolioTableOptimal.propTypes = {
  columns: PropTypes.array,
  optimalPortfolio: PropTypes.object,
  tableColumns: PropTypes.array,
  setTableColumns: PropTypes.func,
};
