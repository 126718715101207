/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import './style.scss';
import { Form, Input, Radio } from 'antd';
import MainButton from '../../components/MainButton';
import { useHistory, useParams } from 'react-router';
import { ReactComponent as PrivatIcon } from './img/icom-privat.svg';
import { ReactComponent as BusnessIcon } from './img/icon-business.svg';
import { ReactComponent as QuestionIcon } from './img/icon-question.svg';
import {
  addRepresentative,
  getClient,
  setClientAddress,
  updateClientInfo,
  setClientType,
  setClientData,
} from '../../data/store/clients/clientActions';
import {
  identificationNumberRules,
  organizationNumberRules,
} from '../../helpers/validation';
import { API_URLS } from '../../constants/api_urls';

const Onboarding = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, isEdit } = useParams();
  const { t } = useTranslation('');
  const { user } = useSelector((state) => state.clientReducer);
  const [accountType, setAccountType] = useState('');

  useEffect(() => {
    dispatch(getClient({ id }));
  }, []);

  useEffect(() => {
    if (user?.type?.type && isEdit !== 'edit') {
      history.replace(`/dashboard/${id}`);
    }
  }, [user]);

  const initialValues = {
    type: user?.type?.type || '',
    identification_number: user?.type?.identification_number || '',
  };
  useEffect(() => {
    if (!accountType) setAccountType(initialValues.type);
  }, [initialValues]);

  const handleChange = (_, allFields) => {
    setAccountType(allFields[0].value ?? '');
  };

  const hasRepresentive = (number) => {
    let response = false;
    user.company_representive.forEach((item) => {
      response = item.personal_number === number;
    });
    return response;
  };

  const getCheckbizCompany = async (orgNumber) => {
    try {
      const resp = await axios.get(
        `${API_URLS.GET_CHECKBIZ_COMPANY}${orgNumber}`,
      );
      if (resp.status === 200) {
        if (resp?.data) {
          const { company_name, city, zip_code } = resp.data;
          const companyInfo = {
            companyName: company_name,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            leiCode: user?.leiCode ?? '',
            phones: user.phones,
          };
          if (company_name) { dispatch(updateClientInfo({ id, client: companyInfo })); }
          const companyAddress = {
            client_id: +id,
            postal: '',
            city,
            zip_code,
            careOf: '',
          };
          if (city || zip_code) { dispatch(setClientAddress({ id, address: companyAddress })); }
        }
        if (resp?.data?.organization_number) {
          const respPersonal = await axios.get(
            `${API_URLS.GET_CHECKBIZ_PERSONAL}${orgNumber}`,
          );
          if (
            respPersonal.status === 200 &&
            respPersonal?.data?.beneficial_owners &&
            Array.isArray(respPersonal.data.beneficial_owners)
          ) {
            const ownershipStructure = [];
            respPersonal.data.beneficial_owners.forEach((item, index) => {
              if (
                !user?.company_representive?.length ||
                !hasRepresentive(item.ssn)
              ) {
                const data = {
                  authority: '',
                  personal_number: item.ssn,
                  first_name: item.firstName,
                  last_name: item.lastName,
                  email: '',
                  mobile_number: '',
                  ownership: item.extent.code === 'INTERVALL2' ? 25 : 0,
                  county_of_citizenship: item?.citizenship ?? '',
                  county_of_tax_residence: item?.countryOfResidence ?? '',
                  documentProves: [],
                };
                dispatch(addRepresentative({ id, representative: data }));
              }
              const dataOwnership = {
                beneficialOwnerID: uuidv4(),
                typeCompanyOwner: item.ssn ? 'Private individual' : 'Company',
                isMissingPersonalNumber: !item.ssn,
                personalNumberOwner: item.ssn,
                firstNameOwner: item.firstName,
                secondNameOwner: item.lastName,
                countryOwner: [item?.citizenship] ?? [],
                countryTaxOwner: item?.countryOfResidence ?? '',
                hasNextOwner:
                  index < respPersonal.data.beneficial_owners.length - 1,
              };
              ownershipStructure.push(dataOwnership);
            });
            const data = {
              type: 'customerKnowledge',
              data: {
                hasControlsMore25: true,
                beneficialOwners: ownershipStructure,
              },
              full_status: '',
            };
            dispatch(setClientData({ id, fields: data }));
          }
        }
      }
    } catch (error) {}

    // return resp.data;
  };
  const onSubmit = useCallback(
    (fields) => {
      const data = { id, type: fields };
      if (
        accountType &&
        (fields.identification_number !== user?.type?.identification_number ||
          fields.type !== user?.type?.type)
      ) {
        dispatch(setClientType(data));
        getCheckbizCompany(fields.identification_number);
      }
      history.push(`/dashboard/${id}`);
    },
    [history, form, accountType],
  );

  return (
    <>
      <h1 className="onboarding-header">{t('WELCOME')}</h1>
      <div className="onboarding-container">
        <h3 className="onboarding-title">{t('WHAT_TYPE_OF_ACCOUNT')}</h3>
        <span className="onboarding-subtitle">
          {t('WANT_TO_START_A_PERSONAL')}
        </span>
        {user && (
          <Form
            form={form}
            name="horizontal_login"
            className="onboarding-title"
            layout="vertical"
            initialValues={initialValues}
            onFieldsChange={(changedFields, allFields) =>
              handleChange(changedFields, allFields)
            }
            onFinish={onSubmit}
          >
            <Form.Item name="type">
              <Radio.Group>
                <div className="input-container">
                  <Radio value="private" />
                  <PrivatIcon />
                  <span className="checkbox-text">{t('PRIVATE_CUSTOMER')}</span>
                  <QuestionIcon />
                </div>
                <div className="input-container">
                  <Radio value="business" />
                  <BusnessIcon />
                  <span className="checkbox-text">
                    {t('BUSINESS_CUSTOMER')}
                  </span>
                  <QuestionIcon />
                </div>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={
                accountType === 'private'
                  ? t('PRIVAT_NUMBER')
                  : accountType === 'business'
                    ? t('ORGANISATION_NUMBER')
                    : t('PRIVAT_ORGANISATION_NUMBER')
              }
              name="identification_number"
              rules={
                accountType === 'private'
                  ? identificationNumberRules
                  : organizationNumberRules
              }
            >
              <Input
                type="text"
                placeholder={t('ENTER_ORGANIZATION_NUMBER')}
                disabled={!accountType}
              />
            </Form.Item>
            <MainButton type="submit" disabled={!accountType}>
              {t('MOVE_ON')}
            </MainButton>
          </Form>
        )}
      </div>
    </>
  );
};

export default Onboarding;
