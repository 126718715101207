import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  useDispatch,
  // useSelector
} from 'react-redux';

import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Tooltip,
} from 'antd';
import Container from '../../components/Container';
import { ReactComponent as Pluse } from '../../assets/add-icon.svg';
import { ReactComponent as File } from '../../assets/file-icon.svg';
import { ReactComponent as Attach } from '../../assets/attach-icon.svg';
import {
  downloadTEMP,
  saveFullPortfolio,
} from '../../data/store/portfolio/portfolioActions';
import { decimalSeparator } from '../../helpers/decimalSeparator';
import CustomInputNumber from '../../components/CustomInputNumber/CustomInputNumber';
import CustomSelect from '../../components/CustomSelect/CustomSelect';

const { Header } = Layout;

const NewPortfolio = ({ userId }) => {
  const { t } = useTranslation('');
  const [form] = Form.useForm();
  const history = useHistory();
  const inputXlsx = useRef(null);
  const dispatch = useDispatch();
  const [fileUploaded, setFileUploaded] = useState(null);

  // const { isLoading } = useSelector((state) => state.portfolioReducer);

  const uploadPortfolio = (event) => {
    const file = event.target.files[0];
    setFileUploaded(file);
  };

  const deleteUploadedFile = () => {
    setFileUploaded(null);
  };

  const handleChangeTaxation = (value) => {
    if (+value === 2) {
      form.setFieldValue('tax_rate', '1.086');
    } else {
      form.setFieldValue('tax_rate', '');
    }
  };

  const handleCancel = () => {
    console.log('Cancel');
    history.goBack();
  };

  const handleCreate = () => {
    form.submit();
  };

  const handleUpload = () => {
    if (inputXlsx.current) {
      inputXlsx.current.click();
    }
  };

  const download = () => {
    dispatch(downloadTEMP());
  };

  const onSubmit = (data) => {
    if (Object.keys(data)?.length) {
      const newPortfolio = {
        data: {
          ...data,
          tax_rate: +data.tax_rate,
          portfolio_code: +data.portfolio_code,
          portfolio_type: +data.portfolio_type,
          deposit_fee: +data.deposit_fee,
          advisory_fee: +data.advisory_fee,
          taxation: +data.taxation,
          clientID: +userId,
          replaceExisted: false,
        },
        history,
        userId,
      };
      if (fileUploaded) {
        dispatch(saveFullPortfolio({ ...newPortfolio, file: fileUploaded }));
      } else {
        dispatch(saveFullPortfolio(newPortfolio));
      }
    }
  };

  return (
    <div className="portfolio-summary-container">
      <Header className="headerStyle">
        <Row className="overview-tabs-wrapper">
          <Col className="tabs-tab-wrapper" span={18}>
            <Button type="link" className="overview-btn ant-btn-active">
              {t('CREATE_PORTFOLIO')}
            </Button>
          </Col>
          <Col className="tabs-btn-wrapper" span={6}>
            <Button type="default" className="edit-btn" onClick={handleCancel}>
              {t('CANCEL')}
            </Button>
            <Button
              type="link"
              className="optimise-btn"
              onClick={handleCreate}
              disabled={false}
            >
              {t('CREATE_PORTFOLIO')}
            </Button>
          </Col>
        </Row>
      </Header>
      <div className="portfolio-title-wrapper">
        <h2 className="portfolio-container-title">{t('PORTFOLIO_CREATION')}</h2>
      </div>
      <div className="portfolio-creat-wrapper">
        <Container widthP={100} className="upload-block">
          <Row justify={'space-between'}>
            <Col span={12}>
              <p className="upload-block-text">{t('WITH_IMPORT_INSTRUMENT')}</p>
            </Col>
            <Col span={12}>
              <Row
                justify={'end'}
                className="upload-action-wrapper"
                align={'middle'}
              >
                <Col>
                  <a onClick={download} className="download-import-temp">
                    {t('DOWNLOAD_THE_TEMPLATE_EXCEL')}
                    <File />
                  </a>
                </Col>
                <Col>
                  <Button
                    type="text"
                    className="portfolio-creat-download"
                    icon={<Pluse />}
                    iconPosition={'end'}
                    onClick={handleUpload}
                  >
                    {t('ADD_PORTFOLIO')}
                  </Button>
                  <input
                    ref={inputXlsx}
                    type="file"
                    accept={'.xlsx'}
                    className="portfolio-creat-download-input"
                    onChange={uploadPortfolio}
                  />
                </Col>
                <Col>
                  {fileUploaded && (
                    <Tooltip
                      placement="top"
                      title={
                        <span style={{ color: '#000000' }}>{t('CLEAR')}</span>
                      }
                      color="white"
                    >
                      <div
                        className="uploaded-name"
                        onClick={deleteUploadedFile}
                      >
                        <Attach />
                        {fileUploaded?.name}
                      </div>
                    </Tooltip>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="portfolio-title-wrapper">
        <h2 className="portfolio-container-title">{t('MAIN_INFORMATION')}</h2>
      </div>
      <div className="portfolio-creat-wrapper">
        <Container widthP={100} className="upload-block">
          <Form
            id="create-portfolio"
            form={form}
            onFinish={onSubmit}
            validateTrigger={['onSubmit', 'onBlur']}
            layout="vertical"
            autoComplete="off"
          >
            <Row className="portfolio-form-row">
              <Col>
                <Form.Item
                  layout='vertical'
                  label={t('NAME')}
                  name="name"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: t('PLEASE_ENTER_A_NAME'),
                    },
                  ]}
                >
                  <Input className="custom-input" />
                </Form.Item>
              </Col>
              <Col>
                <div className="portfolio-number">
                  <Form.Item
                    label={t('PORTFOLIO_NUMBER')}
                    name="portfolio_code"
                    required={false}
                    rules={[
                      {
                        required: true,
                        message: t('PLEASE_ENTER_PORTFOLIO_NUMBER'),
                      },
                    ]}
                  >
                    <CustomInputNumber
                      width={'360px'}
                      className="custom-input"
                      controls={false} />
                  </Form.Item>
                  <p className="portfolio_code-helper">
                    {t('ENTER_PORTFOLIO_ID_FROM_FA')}
                  </p>
                </div>
              </Col>
              <Col style={{ alignSelf: 'center' }}>
                <div className="portfolio-number-sync">
                  <Form.Item name="is_sync_active" valuePropName="checked">
                    <Checkbox className="experience-background-cell center experience-background-checkbox my-details-checkbox">
                      {t('ACTIVATE_SYNCHRONIZATION')}
                    </Checkbox>
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Divider />
            <Row className="portfolio-form-row">
              <Col>
                <Form.Item
                  label={t('TAXATION')}
                  name="taxation"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: t('PLEASE_SELECT_METHOD_TAXATION'),
                    },
                  ]}
                >
                  <CustomSelect
                    width='360px'
                    className="custom-select"
                    onChange={(value) => handleChangeTaxation(value)}
                  >
                    <Select.Option value="1">{t('CAPITAL_GAIN')}</Select.Option>
                    <Select.Option value="2">{t('FLAT_RATE')}</Select.Option>
                  </CustomSelect>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label={t('TAX_RATE')}
                  name="tax_rate"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: t('PLEASE_ENTER_TAX_RATE'),
                    },
                  ]}
                >
                  <CustomInputNumber
                    width={'360px'}
                    percent={true}
                    decimalSeparator={decimalSeparator()}
                    precision={3}
                    className='custom-inputnumber'
                    min={0}
                    step={0.001}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Row className="portfolio-form-row">
              <Col>
                <Form.Item
                  label={t('DEPOSIT_FEE')}
                  name="deposit_fee"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: t('PLEASE_ENTER_DEPOSIT_FEE'),
                    },
                  ]}
                >
                  <CustomInputNumber
                    width={'360px'}
                    percent={true}
                    decimalSeparator={decimalSeparator()}
                    precision={2}
                    className='custom-inputnumber'
                    min={0}
                    step={0.01}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  label={t('ADVISORY_FEE')}
                  name="advisory_fee"
                  required={false}
                  rules={[
                    {
                      required: true,
                      message: t('PLEASE_ENTER_ADVISORY_FEE'),
                    },
                  ]}
                >
                  <CustomInputNumber
                    width={'360px'}
                    percent={true}
                    decimalSeparator={decimalSeparator()}
                    precision={2}
                    className='custom-inputnumber'
                    min={0}
                    step={0.01}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Form.Item
              label={t('PORTFOLIO_TYPE')}
              name="portfolio_type"
              required={false}
              rules={[
                {
                  required: true,
                  message: t('CHOOSE_PORTFOLIO_TYPE'),
                },
              ]}
            >
              <CustomSelect
                className="custom-select">
                <Select.Option value="1">
                  {t('PORTFOLIO_TYPE_ONE')}
                </Select.Option>
                <Select.Option value="2">
                  {t('PORTFOLIO_TYPE_TWO')}
                </Select.Option>
                <Select.Option value="3">
                  {t('PORTFOLIO_TYPE_THREE')}
                </Select.Option>
              </CustomSelect>
            </Form.Item>
          </Form>
        </Container>
      </div>
    </div>
  );
};

export default NewPortfolio;

NewPortfolio.propTypes = {
  userId: PropTypes.string,
};
