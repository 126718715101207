import { actionCreator } from '../../../helpers/actionCreator';

export const GET_ALL_PORTFOLIO = actionCreator('GET_ALL_PORTFOLIO');

export const GET_PORTFOLIO_BY_ID = actionCreator('GET_PORTFOLIO_BY_ID');

export const SAVE_DRAFT_PORTFOLIO = actionCreator('SAVE_DRAFT_PORTFOLIO');

export const SAVE_FULL_PORTFOLIO = actionCreator('SAVE_FULL_PORTFOLIO');

export const DELETE_PORTFOLIO_BY_ID = actionCreator('DELETE_PORTFOLIO_BY_ID');

export const SEARCH_PARAMS = actionCreator('SEARCH_PARAMS');

export const CLEAR_PORTFOLIO_STATE = actionCreator('CLEAR_PORTFOLIO_STATE');

export const CLEAR_PORTFOLIO_LIST = actionCreator('CLEAR_PORTFOLIO_LIST');

export const CHANGE_TO_DRAFT = actionCreator('CHANGE_TO_DRAFT');

export const SET_STEPING = actionCreator('SET_STEPING');

export const CHANGE_TO_CURRENT = actionCreator('CHANGE_TO_CURRENT');

export const EXPORT_PORTFOLIO = actionCreator('EXPORT_PORTFOLIO');

export const EXPORT_SELECTED_PORTFOLIO = actionCreator(
  'EXPORT_SELECTED_PORTFOLIO',
);

export const IMPORT_PORTFOLIO = actionCreator('IMPORT_PORTFOLIO');

export const GET_HISTORY_BY_PORTFOLIO_ID = actionCreator(
  'GET_HISTORY_BY_PORTFOLIO_ID',
);

export const GET_ALL_REPORTS = actionCreator('GET_ALL_REPORTS');

export const DOWNLOAD_REPORT = actionCreator('DOWNLOAD_REPORT');

export const CREATE_REPORT = actionCreator('CREATE_REPORT');

export const OPTIMIZATION_PORTFOLIO = actionCreator('OPTIMIZATION_PORTFOLIO');

export const CREATE_PORTFOLIO_TASK_ANALYSIS = actionCreator(
  'CREATE_PORTFOLIO_TASK_ANALYSIS',
);

export const CREATE_PORTFOLIO_TASK_PROSPECTING = actionCreator(
  'CREATE_PORTFOLIO_TASK_PROSPECTING',
);

export const CLEAR_PORTFOLIO_PROSPECTING = actionCreator(
  'CLEAR_PORTFOLIO_PROSPECTING',
);

export const GET_STEP_OPTIMISATION_PORTFOLIO = actionCreator(
  'GET_STEP_OPTIMISATION_PORTFOLIO',
);

export const POST_STEP_OPTIMISATION_PORTFOLIO = actionCreator(
  'POST_STEP_OPTIMISATION_PORTFOLIO',
);

export const SEND_FINANCIAL_PLAN = actionCreator('SEND_FINANCIAL_PLAN');

export const PORTFOLIO_FINAL = actionCreator('PORTFOLIO_FINAL');

export const SEND_DATA_PDF_PORTFOLIO = actionCreator('SEND_DATA_PDF_PORTFOLIO');

export const MAPPING_LINK_PORTFOLIO = actionCreator('MAPPING_LINK_PORTFOLIO');

export const DOWNLOAD_TEMP = actionCreator('DOWNLOAD_TEMP');

export const GET_CANDIDATES = actionCreator('GET_CANDIDATES');

export const CLEAR_CANDIDATES = actionCreator('CLEAR_CANDIDATES');

export const GET_TRANSACTIONS = actionCreator('GET_TRANSACTIONS');
