import React from 'react';

import PropTypes, { bool } from 'prop-types';

import { Flex } from 'antd';
import PortfolioListItem from './PortfolioListItem';
import SkeletonClientInfo from './Skeleton/SkeletonClientInfo';

const PortfolioList = ({ list, selectedRowKeys, setSelectedRowKeys, isLoading }) => {
  if (isLoading) {
    return <SkeletonClientInfo />;
  }
  return (
    <Flex className="portfolio-list-wrapper">
    {
      list?.slice()?.map(item => {
        return (
          <PortfolioListItem
            key={item.ID}
            item={item}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
          />
        );
      })
    }
    </Flex>
  );
};

export default PortfolioList;

PortfolioList.propTypes = {
  list: PropTypes.array,
  selectedRowKeys: PropTypes.array,
  setSelectedRowKeys: PropTypes.func,
  isLoading: bool
};
