/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getSuggestionsPortfolio } from '../../data/store/clients/clientActions';

import { Button, Col, Row, Table } from 'antd';
import Skeleton from 'react-loading-skeleton';
import CustomSearchInput from '../../components/CustomSearchInput/CustomSearchInput';
import Container from '../../components/Container';

import { ReactComponent as Filter } from '../../assets/filter-icon.svg';
import { ReactComponent as Delete } from '../../assets/delete-black-icon.svg';
import { ReactComponent as Eye } from '../../assets/eye-icon.svg';
import { ReactComponent as Download } from '../../assets/export-icon.svg';

const ImplementedTable = ({ userId }) => {
  const { t } = useTranslation('');
  const dispatch = useDispatch();

  const { suggestions, isLoading } = useSelector(
    (state) => state.clientReducer
  );

  const [tableColumns, setTableColumns] = useState();
  const [filteredPortfolio, setFilteredPortfolio] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterCounter, setFilterCounter] = useState(null);

  useEffect(() => {
    dispatch(getSuggestionsPortfolio(userId));
    return () => {
      dispatch({ type: 'CLEAR_SUGGESTIONS_PORTFOLIO' });
    };
  }, []);

  const textSort = (a, b, direction = 1) => {
    if (a?.toLowerCase() < b?.toLowerCase()) {
      return -1 * direction;
    }
    if (a?.toLowerCase() > b?.toLowerCase()) {
      return 1 * direction;
    }
    return 0;
  };

  useEffect(() => {
    const defaultColumns = [
      {
        title: t('PORTFOLIO_NUMBER'),
        dataIndex: 'name',
        className: 'suggestions-name',
        width: '',
        sorter: (a, b) => textSort(a.name, b.name),
      },
      {
        title: t('CREATION_DATE'),
        dataIndex: 'createAt',
        className: 'suggestions-create-date',
        width: '',
        align: 'left',
        sorter: (a, b) => textSort(a.createAt, b.createAt),
      },
      {
        title: t('RETURN'),
        dataIndex: 'current_mean_return',
        className: 'suggestions-return',
        width: '',
        align: 'center',
        sorter: (a, b) => a.return - b.return,
      },
      {
        title: t('VOLATILITY'),
        dataIndex: 'current_volatility',
        className: 'suggestions-volatility',
        width: '',
        align: 'center',
        sorter: (a, b) => a.volatility - b.volatility,
      },
      {
        title: 'CVar',
        dataIndex: 'current_cvar',
        className: 'suggestions-cvar',
        width: '',
        align: 'left',
        sorter: (a, b) => a.value - b.value,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('ACTIONS'),
        align: 'center',
        dataIndex: 'operation',
        width: '15%',
        render: (_, record) => {
          return (
            <div className="cell-button-wrapper">
              <Button
                className="action-btn advisor-action-btn approve"
                onClick={() => handlApproval(record.id)}
              >
                {t('APPROVE')}
              </Button>
              <Button type="text" onClick={() => hendlePreview(record.id)}>
                <Eye />
              </Button>
              <Button type="text" onClick={() => hendleDownload(record.id)}>
                <Download />
              </Button>
              <Button type="text" onClick={() => handleDelete(record.id)}>
                <Delete />
              </Button>
            </div>
          );
        },
      },
    ];
    setTableColumns(defaultColumns);
  }, [suggestions]);

  const handeleFilter = () => {
    setOpenFilter(true);
  };

  const handleSearch = (event) => {
    const { value } = event?.target;
    // TODO rework
    const data = [];
    if (value?.length > 2) {
      const searched = data?.filter(
        (i) =>
          i.instrument?.toLowerCase().includes(value?.toLowerCase()) ||
          i.ISIN?.toLowerCase().includes(value?.toLowerCase())
      );
      setFilteredPortfolio(searched);
    } else {
      setFilteredPortfolio(data);
    }
  };

  const hendlePreview = (id) => {
    console.log('🚀 ~ hendlePreview ~ id:', id);
  };

  const hendleDownload = (id) => {
    console.log('🚀 ~ hendleDownload ~ id:', id);
  };

  const handleDelete = (id) => {
    console.log('🚀 ~ handleDelete ~ id:', id);
  };

  const handlApproval = (id) => {
    console.log('🚀 ~ handlApproval ~ id:', id);
  };

  return (
    <Container widthP={100} mb="28px" padding="0">
      <Row
        justify={'space-between'}
        align={'middle'}
        style={{ marginBottom: '19px', padding: '19px 40px 0' }}
      >
        <Col>
          <h2 className="instrument-header-title">
            {t('APPROVED')}
          </h2>
        </Col>
        <Col className="instrument-button-wraper">
          <CustomSearchInput
            placeholder={t('SEARCH_CODE')}
            onChange={handleSearch}
          />
          <Button
            className="filter-btn"
            type="default"
            onClick={handeleFilter}
            icon={<Filter />}
            iconPosition="end"
          >
            {t('FILTER')}
            {filterCounter
              ? (
              <span className="filter-counter">{filterCounter}</span>
                )
              : (
                  ''
                )}
          </Button>
        </Col>
      </Row>
      <div className="suggestions-table">
        {isLoading
          ? (
          <Skeleton containerClassName="skeleton-container-flex table-skeleton" />
            )
          : (
          <Table
            rowKey={(record) => record.id}
            rowClassName={(_, index) =>
              index % 2 === 0
                ? 'holdings-table-row'
                : 'holdings-table-row is-odd'
            }
            columns={tableColumns}
            dataSource={suggestions}
            size="middle"
            bordered
            pagination={false}
          />
            )}
      </div>
    </Container>
  );
};

export default ImplementedTable;

ImplementedTable.propTypes = {
  userId: PropTypes.number,
  data: PropTypes.array,
};
